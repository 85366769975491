import { useUpdateShortListCriteriaFormByOrganizationId } from 'hooks/mutations/shortlist-criteria/shortlist-criteria';
import { useGetShortListCriteriaFormByOrganizationId } from 'hooks/queries/shortlist-criteria/shortlist-criteria';
import { FC, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InvestmentPlanRoutes } from 'routes/constants';

import { Grid } from '@mui/material';

import { InvestmentPlanLayout } from 'components/investment-plan-layout/investment-plan-layout';
import { PaperLayout } from 'components/paper-layout/paper-layout';

import { AppContext } from 'context';

import { ShortlistCriteriaForm } from './shortlist-criteria-form/shortlist-criteria-form';

export const ShortlistCriteriaPage: FC = () => {
  const { organizationId } = useParams();
  const {
    commentsUniquePathId: { setCommentsUniquePathId },
  } = useContext(AppContext);

  const { data: shortlistCriteriaData, isFetching } =
    useGetShortListCriteriaFormByOrganizationId(organizationId);
  const { mutateAsync: updateShortListCriteriaFormByOrganizationId } =
    useUpdateShortListCriteriaFormByOrganizationId();

  const methods = useForm({ defaultValues: undefined });
  const { watch, reset } = methods;

  const handleUpdateFormOnBlur = async (fieldName: string) => {
    const value = watch(fieldName);

    if (value === null) return;

    const data = {
      path: fieldName,
      value: typeof value === 'boolean' ? value : Number(value),
    };

    await updateShortListCriteriaFormByOrganizationId({
      id: organizationId,
      data,
    });
  };

  useEffect(() => {
    if (!isFetching && shortlistCriteriaData) {
      reset(shortlistCriteriaData);
    }
  }, [shortlistCriteriaData, isFetching, reset]);

  useEffect(() => {
    if (!isFetching) {
      const uniqueCommentsPath = `${organizationId}${InvestmentPlanRoutes.SHORTLIST_CRITERIA}`;

      setCommentsUniquePathId(uniqueCommentsPath);
    }
  }, [isFetching, organizationId, setCommentsUniquePathId]);

  return (
    <InvestmentPlanLayout sectionName="shortlist_criteria_page" renderTooltip>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <PaperLayout borderRadius={4}>
            <FormProvider {...methods}>
              <ShortlistCriteriaForm
                data={shortlistCriteriaData}
                onUpdateForm={handleUpdateFormOnBlur}
              />
            </FormProvider>
          </PaperLayout>
        </Grid>
      </Grid>
    </InvestmentPlanLayout>
  );
};
