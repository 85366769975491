import { FC, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Divider,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { UtilityAssessmentContext } from 'pages/utility-assessment-page/context';

import { InputLinkUpload } from 'components/link-upload/link-upload';
import { PageLoader } from 'components/page-loader/page-loader';

import { COMMON_FORM_CONTROL_LABEL_STYLES } from 'constants/index';

import { getValue, toCamelCase } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { TEXT_FIELD_KEY, TRANSLATION_PATH_PREFIX } from './constants';
import {
  StyledSectionTitle,
  StyledTableBodyCell,
  StyledTableHeaderCell,
  StyledTableHeaderColumnCell,
} from './styles';
import { AssessmentTableSectionProps } from './types';

export const AssessmentTableSection: FC<AssessmentTableSectionProps> = ({
  data,
  titleKey,
  pathPrefix,
  columns,
  onUpdate,
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const { control } = useFormContext();
  const {
    technicalAndEfficiencyAssessmentTab: {
      isFetchingTechnicalAndEfficiencyAssessmentData: isFetching,
    },
  } = useContext(UtilityAssessmentContext);

  return (
    <>
      {isFetching ? (
        <PageLoader />
      ) : (
        data.map(({ table, title, listTypeKey }, index) => (
          <Grid key={index} container rowSpacing={3}>
            {title && (
              <Grid item xs={12}>
                <StyledSectionTitle>
                  {t(`${TRANSLATION_PATH_PREFIX}.${titleKey}.${title}`)}
                </StyledSectionTitle>
              </Grid>
            )}

            <Grid item xs={12} sx={{ overflowX: 'auto', pb: 2 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell>
                      {t(
                        `${TRANSLATION_PATH_PREFIX}.${titleKey}.${listTypeKey}`,
                      )}
                    </StyledTableHeaderCell>

                    {columns.map((column, colIndex) => (
                      <StyledTableHeaderColumnCell key={colIndex}>
                        <Typography sx={{ fontSize: 12, fontWeight: 700 }}>
                          {t(
                            `${TRANSLATION_PATH_PREFIX}.${titleKey}.${column}.title`,
                          )}
                        </Typography>

                        <Typography sx={{ fontSize: 12 }}>
                          {t(
                            `${TRANSLATION_PATH_PREFIX}.${titleKey}.${column}.unitsOfMeasurement`,
                            '',
                          )}
                        </Typography>
                      </StyledTableHeaderColumnCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {table.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <StyledTableBodyCell>{rowIndex + 1}</StyledTableBodyCell>

                      {columns.map((column, colIndex) => (
                        <TableCell
                          key={`${rowIndex}-${colIndex}`}
                          sx={{ border: 'none', p: 2 }}
                        >
                          <Controller
                            name={`${pathPrefix}.${index}.table.${rowIndex}.${toCamelCase(column)}${column === TEXT_FIELD_KEY ? `.${currentLanguage}` : ''}`}
                            control={control}
                            defaultValue={row[toCamelCase(column)] || undefined}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type={
                                  column === TEXT_FIELD_KEY ? 'text' : 'number'
                                }
                                variant="outlined"
                                size="small"
                                fullWidth
                                onBlur={() => onUpdate(field.name)}
                              />
                            )}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12}>
              <Controller
                name={`${pathPrefix}.${index}.explanation.${currentLanguage}`}
                control={control}
                render={({ field, fieldState: { error, isDirty } }) => (
                  <FormControlLabel
                    sx={COMMON_FORM_CONTROL_LABEL_STYLES}
                    control={
                      <TextField
                        {...field}
                        value={getValue(field.value, currentLanguage)}
                        error={!!error}
                        multiline
                        rows={7}
                        helperText={error?.message}
                        fullWidth
                        onBlur={() => {
                          if (isDirty) {
                            onUpdate(field.name);
                          }
                        }}
                      />
                    }
                    label={
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          mb: 0.5,
                        }}
                      >
                        {t('utility_assessment_page.explanation')}
                      </Typography>
                    }
                    labelPlacement="top"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <InputLinkUpload
                control={control}
                fieldName={`${pathPrefix}.${index}.attachments`}
                onUpdateTemplate={() =>
                  onUpdate(`${pathPrefix}.${index}.attachments`)
                }
              />
            </Grid>

            {index < data.length - 1 && (
              <Grid item xs={12}>
                <Divider sx={{ mb: 3 }} />
              </Grid>
            )}
          </Grid>
        ))
      )}
    </>
  );
};
