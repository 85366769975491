import { useGetOrganizationById } from 'hooks/queries/organizations/organizations';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';

import { StyledIconButton } from 'components/confirmation-dialog/styles';
import { PageLoader } from 'components/page-loader/page-loader';
import { commonFormControlLabelStyles } from 'components/project-creation-dialog/styles';

import { LinkDialogCreationProps } from './types';

export const LinkDialogCreation: FC<LinkDialogCreationProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { organizationId } = useParams();

  const { data: organization, isLoading: isLoadingOrganization } =
    useGetOrganizationById(organizationId);

  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');

  const handleAddAttachment = () => {
    if (!inputValue.trim()) {
      setError(t('empty_field'));
      return;
    }

    onSubmit({ name: '', url: inputValue, id: '' });

    setInputValue('');
    setError('');
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle sx={{ pb: 5 }}>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </DialogTitle>

      <DialogContent>
        {isLoadingOrganization ? (
          <PageLoader />
        ) : (
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                {t('link_dialog_creation.upload_file')}
              </Typography>

              <IconButton
                size="large"
                onClick={() => window.open(organization?.storageUrl, '_blank')}
              >
                <DriveFolderUploadOutlinedIcon />
              </IconButton>
            </Box>

            <FormControlLabel
              sx={commonFormControlLabelStyles}
              control={
                <TextField
                  onChange={(event) => {
                    setInputValue(event.target.value);
                    setError('');
                  }}
                  error={!!error}
                  helperText={error}
                  value={inputValue}
                  fullWidth
                  size="small"
                />
              }
              label={
                <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                  {t('link_dialog_creation.save_link')}
                </Typography>
              }
              labelPlacement="top"
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ px: 3, pb: 3 }}>
        <Button variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>

        <Button variant="contained" onClick={handleAddAttachment}>
          {t('link_dialog_creation.add_attachment')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
