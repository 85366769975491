import { useCallback, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Grid, TextField, Typography } from '@mui/material';

import { Switch } from 'components/switch/switch';

import { CriteriaOptions } from './criteria-options/criteria-options';
import { CriteriaTitle } from './criteria-title/criteria-title';
import { StyledCriteriaWrapper, StyledWeightWrapper } from './styles';
import { ShortlistCriteriaFormProps } from './types';

export const ShortlistCriteriaForm = ({
  data,
  onUpdateForm,
}: ShortlistCriteriaFormProps) => {
  const { t } = useTranslation();
  const { control, setError, setValue, trigger, watch } = useFormContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const criteriaData = data
    ? Object.keys(data)
        .filter(
          (fieldKey) => !['_id', 'organizationId', '__v'].includes(fieldKey),
        )
        .map((dataKey) => ({
          dataKey,
          isCriteriaApplied: watch(`${dataKey}.isApplied`),
          criteriaOptions: watch(`${dataKey}.options`),
          weights: Number(watch(`${dataKey}.weights`)),
        }))
    : [];

  const totalWeights = useMemo(
    () =>
      criteriaData.reduce(
        (sum, { isCriteriaApplied, weights }) =>
          isCriteriaApplied ? sum + (weights ?? 0) : sum,
        0,
      ),
    [criteriaData],
  );

  const handleUpdateForm = useCallback(
    async (key: string) => {
      const isValidField = await trigger(key);

      if (!isValidField) {
        return setError(key, {
          type: 'manual',
          message: t('errors.weights_min_max_error'),
        });
      }

      if (totalWeights > 100) {
        return setError(key, {
          type: 'manual',
          message: t('errors.weights_total_exceeded_error'),
        });
      }

      onUpdateForm(key);
    },
    [onUpdateForm, trigger, setError, totalWeights, t],
  );

  return (
    <form>
      <Box px={4}>
        {criteriaData.map(
          ({ dataKey, isCriteriaApplied, criteriaOptions }, dataKeyIndex) => (
            <Box key={dataKey} pb={4} pt={dataKeyIndex === 0 ? 4 : 0}>
              <StyledCriteriaWrapper isCriteriaApplied={isCriteriaApplied}>
                <Grid container columnGap={3}>
                  <Grid item xs={10}>
                    <Controller
                      name={`${dataKey}.isApplied`}
                      control={control}
                      render={({ field }) => (
                        <Switch
                          {...field}
                          checked={field.value ?? false}
                          sx={{ mb: 1 }}
                          onChange={(event) => {
                            setValue(
                              `${dataKey}.isApplied`,
                              event.target.checked,
                            );
                            handleUpdateForm(`${dataKey}.isApplied`);
                          }}
                        />
                      )}
                    />

                    <CriteriaTitle
                      titleKey={dataKey}
                      isCriteriaApplied={isCriteriaApplied}
                    />
                  </Grid>

                  {isCriteriaApplied && (
                    <StyledWeightWrapper item xs>
                      <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                        {t('shortlist_criteria_page.weights')}
                      </Typography>

                      <Controller
                        name={`${dataKey}.weights`}
                        control={control}
                        rules={{ min: 0, max: 100 }}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            value={field.value ?? undefined}
                            error={!!error}
                            helperText={error?.message}
                            onBlur={() =>
                              handleUpdateForm(`${dataKey}.weights`)
                            }
                            type="number"
                            size="small"
                            fullWidth
                            inputProps={{ min: 0, max: 100 }}
                          />
                        )}
                      />
                    </StyledWeightWrapper>
                  )}
                </Grid>

                {isCriteriaApplied && (
                  <CriteriaOptions options={criteriaOptions} />
                )}
              </StyledCriteriaWrapper>
            </Box>
          ),
        )}
      </Box>
    </form>
  );
};
