import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { toSnakeCase } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { TRANSLATION_KEY } from '../constants';
import { StyledExplanationBoxWrapper } from '../styles';
import { QuestionFieldProps } from './types';

export const QuestionField: FC<QuestionFieldProps> = ({
  id,
  question,
  sectionKey,
  translationKey,
  onUpdateForm,
}) => {
  const { t } = useTranslation();
  const { currentLanguage } = useIsEnLanguage();
  const { control, watch } = useFormContext();

  const { titleKey, descriptionKey, options } = question;

  const fieldValue = watch
    ? watch(`${sectionKey}.questions.${id}.value`)
    : undefined;
  const isOtherSelected = options?.includes('Other') && fieldValue === 'Other';

  const titleTranslationKey = `${TRANSLATION_KEY}.${toSnakeCase(translationKey)}.${toSnakeCase(titleKey)}`;
  const descriptionTranslationKey = descriptionKey
    ? `${TRANSLATION_KEY}.${toSnakeCase(translationKey)}.${toSnakeCase(descriptionKey)}`
    : null;

  return (
    <Box sx={{ width: '100%' }}>
      <FormControlLabel
        sx={{ alignItems: 'flex-start', mx: 'unset', width: '100%' }}
        control={
          options ? (
            <StyledExplanationBoxWrapper>
              <Controller
                name={`${sectionKey}.questions.${id}.value`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    size="small"
                    fullWidth
                    onBlur={() =>
                      onUpdateForm(`${sectionKey}.questions.${id}.value`)
                    }
                  >
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {isOtherSelected && (
                <Controller
                  name={`${sectionKey}.questions.${id}.explanation.${currentLanguage}`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControlLabel
                      sx={{
                        alignItems: 'flex-start',
                        mx: 'unset',
                        width: '100%',
                      }}
                      control={
                        <TextField
                          {...field}
                          error={!!error}
                          helperText={error?.message}
                          onBlur={() =>
                            onUpdateForm(
                              `${sectionKey}.questions.${id}.explanation.${currentLanguage}`,
                            )
                          }
                          size="small"
                          fullWidth
                        />
                      }
                      label={
                        <Typography
                          sx={{
                            fontSize: 14,
                            fontWeight: 700,
                            mb: 0.5,
                          }}
                        >
                          {t(`${TRANSLATION_KEY}.explain`)}{' '}
                          {t(titleTranslationKey).toLowerCase()}
                        </Typography>
                      }
                      labelPlacement="top"
                    />
                  )}
                />
              )}
            </StyledExplanationBoxWrapper>
          ) : (
            <Controller
              name={`${sectionKey}.questions.${id}.value.${currentLanguage}`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error}
                  helperText={error?.message}
                  onBlur={() =>
                    onUpdateForm(
                      `${sectionKey}.questions.${id}.value.${currentLanguage}`,
                    )
                  }
                  size="small"
                  fullWidth
                />
              )}
            />
          )
        }
        label={
          <>
            <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
              {t(titleTranslationKey)}
            </Typography>

            {descriptionTranslationKey && (
              <Typography sx={{ fontSize: 14, mb: 0.5 }}>
                {t(descriptionTranslationKey)}
              </Typography>
            )}
          </>
        }
        labelPlacement="top"
      />
    </Box>
  );
};
