import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTBD } from './styles';

export const TBDCellRenderer: FC<ICellRendererParams> = () => {
  const { t } = useTranslation();

  return <StyledTBD>{t('tbd')}</StyledTBD>;
};
