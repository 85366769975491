import { usePatchProject } from 'hooks/queries/investment-project/investment-project';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { StyledLightTooltip } from 'components/investment-plan-layout/styles';
import { LinkDialogCreation } from 'components/link-upload/link-dialog-creation/link-dialog-creation';
import {
  isOtherOptionChosen,
  isOtherOptionThanNoChosen,
  isOtherServiceChosen,
  isOtherTargetedBenefitsChosen,
  isOtherThemeChosen,
  ownFundingIsPresented,
} from 'components/project-creation-dialog/project-creation-form/utils';
import { commonFormControlLabelStyles } from 'components/project-creation-dialog/styles';

import {
  AttachmentStructure,
  InvestmentProject,
  InvestmentProjectMetadata,
  PatchProject,
} from 'models/investment-project.model';

import { getValue } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { renderTooltipTitle } from './categories-tooltip';
import ProjectDetailsPanelLinkUpload from './project-details-panel-link-upload';

interface ProjectDetailsFormProps {
  projectId: string;
  investmentProjectMetadata: InvestmentProjectMetadata;
  defaultValues: any;
}

const ProjectDetailsForm: FC<ProjectDetailsFormProps> = ({
  projectId,
  investmentProjectMetadata,
  defaultValues,
}) => {
  const { mutateAsync: patchProject } = usePatchProject(projectId);
  const { currentLanguage } = useIsEnLanguage();
  const { t } = useTranslation();
  const {
    services,
    targeted_benefits,
    categories,
    themes,
    yesWithStudyNoOtherOptions,
    yesWithNamesNoOtherOptions,
    yesNoOtherOptions,
    ownFundingOptions,
    project_phases,
    yesMaybeNoOtherOptions,
    urgencyLevels,
  } = investmentProjectMetadata;
  const {
    control,
    getValues,
    formState: { errors, dirtyFields },
    watch,
    setValue,
    reset,
  } = useFormContext();
  const [
    service,
    theme,
    donorIsRequired,
    feasibilityStudies,
    targetedBenefits,
    priorityFactsheet,
    ownFundingAvailable,
    fundingDiscussedWithDonors,
    projectPhasing,
    feasibilityStudiesCanStart,
    implementationCanStart,
    urgencyLevel,
  ] = watch([
    'service.value',
    'theme.value',
    'donorIsRequired.value',
    'feasibilityStudies.value',
    'targetedBenefits.value',
    'priorityFactsheet.value',
    'ownFundingAvailable.value',
    'fundingDiscussedWithDonors.value',
    'projectPhasing.value',
    'feasibilityStudiesCanStart.value',
    'implementationCanStart.value',
    'urgencyLevel.value',
  ]);

  const [fieldKeyAttachments, setFieldKeyAttachments] = useState<string | null>(
    null,
  );

  useEffect(() => {
    if (!isOtherServiceChosen(services, service)) {
      setValue('service.details', null);
    }
  }, [service, services, setValue]);
  useEffect(() => {
    if (!isOtherOptionChosen(donorIsRequired)) {
      setValue('donorIsRequired.details', null);
    }
  }, [donorIsRequired, setValue]);
  useEffect(() => {
    if (!isOtherOptionThanNoChosen(fundingDiscussedWithDonors)) {
      setValue('fundingDiscussedWithDonors.details', null);
    }
  }, [fundingDiscussedWithDonors, setValue]);
  useEffect(() => {
    if (!isOtherOptionChosen(projectPhasing)) {
      setValue('projectPhasing.details', null);
    }
  }, [projectPhasing, setValue]);
  useEffect(() => {
    if (!isOtherOptionChosen(feasibilityStudiesCanStart)) {
      setValue('feasibilityStudiesCanStart.details', null);
    }
  }, [feasibilityStudiesCanStart, setValue]);
  useEffect(() => {
    if (!isOtherOptionChosen(urgencyLevel)) {
      setValue('urgencyLevel.details', null);
    }
  }, [urgencyLevel, setValue]);
  useEffect(() => {
    if (!isOtherOptionChosen(implementationCanStart)) {
      setValue('implementationCanStart.details', null);
    }
  }, [implementationCanStart, setValue]);
  useEffect(() => {
    if (!isOtherOptionThanNoChosen(feasibilityStudies)) {
      setValue('feasibilityStudies.details', null);
    }
  }, [feasibilityStudies, setValue]);
  useEffect(() => {
    if (!isOtherThemeChosen(themes, theme)) {
      setValue('theme.details', null);
    }
  }, [themes, theme, setValue]);

  useEffect(() => {
    if (!ownFundingIsPresented(ownFundingAvailable)) {
      setValue('ownFundingAvailable.details', null);
    }
  }, [ownFundingAvailable, setValue]);

  useEffect(() => {
    if (!isOtherOptionThanNoChosen(priorityFactsheet)) {
      setValue('priorityFactsheet.details', null);
    }
  }, [priorityFactsheet, setValue]);

  useEffect(() => {
    if (!isOtherTargetedBenefitsChosen(targetedBenefits)) {
      setValue('targetedBenefits.details', null);
    }
  }, [setValue, targetedBenefits]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleBlur = async (fieldName: string, translatable = true) => {
    let payload: PatchProject | null = null;

    if (fieldName.includes('.')) {
      const [firstPart, secondPart] = fieldName.split('.');

      if (dirtyFields?.[firstPart]?.[secondPart]) {
        const value = getValues(fieldName);

        if (value && secondPart === 'value') {
          let details = undefined;

          if (
            firstPart === 'service' &&
            !isOtherServiceChosen(services, service)
          ) {
            details = null;
          } else if (
            firstPart === 'donorIsRequired' &&
            !isOtherOptionChosen(donorIsRequired)
          ) {
            details = null;
          } else if (
            firstPart === 'fundingDiscussedWithDonors' &&
            !isOtherOptionThanNoChosen(fundingDiscussedWithDonors)
          ) {
            details = null;
          } else if (
            firstPart === 'projectPhasing' &&
            !isOtherOptionChosen(projectPhasing)
          ) {
            details = null;
          } else if (
            firstPart === 'feasibilityStudiesCanStart' &&
            !isOtherOptionChosen(feasibilityStudiesCanStart)
          ) {
            details = null;
          } else if (
            firstPart === 'urgencyLevel' &&
            !isOtherOptionChosen(urgencyLevel)
          ) {
            details = null;
          } else if (
            firstPart === 'implementationCanStart' &&
            !isOtherOptionChosen(implementationCanStart)
          ) {
            details = null;
          } else if (
            firstPart === 'feasibilityStudies' &&
            !isOtherOptionThanNoChosen(feasibilityStudies)
          ) {
            details = null;
          } else if (
            firstPart === 'theme' &&
            !isOtherThemeChosen(themes, theme)
          ) {
            details = null;
          } else if (
            firstPart === 'ownFundingAvailable' &&
            !ownFundingIsPresented(ownFundingAvailable)
          ) {
            details = null;
          } else if (
            firstPart === 'priorityFactsheet' &&
            !isOtherOptionThanNoChosen(priorityFactsheet)
          ) {
            details = null;
          } else if (
            firstPart === 'targetedBenefits' &&
            !isOtherTargetedBenefitsChosen(targetedBenefits)
          ) {
            details = null;
          }

          if (typeof details !== 'undefined') {
            payload = {
              [firstPart]: {
                value,
                details: null,
              },
            };
          } else {
            payload = {
              [firstPart]: {
                value,
              },
            };
          }
        } else if (value) {
          payload = {
            [firstPart]: {
              [secondPart]: translatable ? { [currentLanguage]: value } : value,
            },
          };
        }
      }
    } else {
      if (dirtyFields[fieldName]) {
        const value = getValues(fieldName);

        if (value) {
          payload = {
            [fieldName]: translatable ? { [currentLanguage]: value } : value,
          };
        }
      }
    }
    if (payload) {
      patchProject({ projectPatchPayload: payload, fieldName, translatable });
    }
  };

  const handleDeleteAttachment = (fieldName: string, attachmentUrl: string) => {
    const attachments = getValues(fieldName) as AttachmentStructure[];

    patchProject(
      {
        projectPatchPayload: {
          [fieldName]: attachments.filter((a) => a.url !== attachmentUrl),
        },
        fieldName,
        translatable: false,
      },
      {
        onSuccess: () => {
          setValue(
            fieldName,
            attachments.filter((a) => a.url !== attachmentUrl),
          );
        },
      },
    );
  };

  const handleAttachmentUpload = (
    fieldName: string,
    value: (AttachmentStructure | string)[],
  ) => {
    const payload = {
      [fieldName]: value.map((att) =>
        typeof att === 'object' ? att : { url: att, name: '' },
      ),
    };

    patchProject(
      {
        projectPatchPayload: payload,
        fieldName,
        translatable: false,
      },
      {
        onSuccess: (data) => {
          setValue(fieldName, data[fieldName as keyof InvestmentProject]);
          setFieldKeyAttachments(null);
        },
      },
    );
  };

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            defaultValue={defaultValues.name}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <TextField
                    {...field}
                    value={getValue(field.value, currentLanguage)}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    onBlur={() => handleBlur('name')}
                  />
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t('investment_projects_page.project_creation_dialog.name')}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="service.value"
            control={control}
            defaultValue={defaultValues.service.value}
            render={({ field }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <RadioGroup
                    {...field}
                    onBlur={() => handleBlur('service.value', false)}
                    name="service-radio-buttons-group"
                  >
                    {services.map((service) => (
                      <FormControlLabel
                        key={service.name}
                        value={service._id}
                        control={<Radio />}
                        label={t(`services.${service.name}`)}
                      />
                    ))}
                  </RadioGroup>
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.service',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
        </Grid>
        {isOtherServiceChosen(services, service) && (
          <Grid item xs={12}>
            <Controller
              name="service.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      error={!!error}
                      value={getValue(field.value, currentLanguage)}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() => handleBlur('service.details')}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.service_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.category}>
            <Controller
              name="category"
              control={control}
              defaultValue={defaultValues.category}
              render={({ field }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      onBlur={() => handleBlur('category', false)}
                      fullWidth
                    >
                      {categories.map((category) => {
                        return (
                          <MenuItem key={category.name} value={category._id}>
                            {t(`categories.${category.name}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                        {t(
                          'investment_projects_page.project_creation_dialog.category',
                        )}
                      </Typography>
                      <StyledLightTooltip
                        title={renderTooltipTitle(t)}
                        arrow
                        placement="right-end"
                      >
                        <IconButton sx={{ ml: 1 }}>
                          <InfoOutlinedIcon sx={{ color: 'graymodern.400' }} />
                        </IconButton>
                      </StyledLightTooltip>
                    </Box>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.category && (
              <FormHelperText>
                {errors.category?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="totalCost"
            control={control}
            defaultValue={defaultValues.totalCost}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <TextField
                    {...field}
                    type="number"
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    onBlur={() => {
                      handleBlur('totalCost', false);
                    }}
                  />
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.total_cost',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.targetedBenefits}>
            <Controller
              name="targetedBenefits.value"
              control={control}
              defaultValue={defaultValues.targetedBenefits.value}
              render={({ field }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      onBlur={() => handleBlur('targetedBenefits.value', false)}
                      multiple
                      fullWidth
                    >
                      {targeted_benefits.map((benefit) => {
                        return (
                          <MenuItem key={benefit} value={benefit}>
                            {t(`targeted_benefits.${benefit}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.targeted_benefits',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.targetedBenefits && (
              <FormHelperText>
                {errors.targetedBenefits?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherTargetedBenefitsChosen(targetedBenefits) && (
          <Grid item xs={12}>
            <Controller
              name="targetedBenefits.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      value={getValue(field.value, currentLanguage)}
                      error={!!error}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() => handleBlur('targetedBenefits.details')}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.targeted_benefits_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.feasibilityStudies}>
            <Controller
              name="feasibilityStudies.value"
              control={control}
              defaultValue={defaultValues.feasibilityStudies.value}
              render={({ field }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      onBlur={() =>
                        handleBlur('feasibilityStudies.value', false)
                      }
                      fullWidth
                    >
                      {yesWithStudyNoOtherOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(`dropdown_options.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.feasibility_studies',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.feasibilityStudies && (
              <FormHelperText>
                {errors.feasibilityStudies?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherOptionThanNoChosen(feasibilityStudies) && (
          <Grid item xs={12}>
            <Controller
              name="feasibilityStudies.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      value={getValue(field.value, currentLanguage)}
                      error={!!error}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() => handleBlur('feasibilityStudies.details')}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.feasibility_studies_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
            {t(
              'investment_projects_page.project_creation_dialog.feasibility_studies_can_start_attachments',
            )}
          </Typography>
          <Controller
            name="feasibilityStudiesAttachments"
            control={control}
            render={({ field }) => (
              <ProjectDetailsPanelLinkUpload
                fieldName={field.name}
                attachments={field.value}
                handleDelete={handleDeleteAttachment}
                openDialog={() =>
                  setFieldKeyAttachments('feasibilityStudiesAttachments')
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.feasibilityStudiesCanStart}>
            <Controller
              name="feasibilityStudiesCanStart.value"
              control={control}
              defaultValue={defaultValues.feasibilityStudiesCanStart.value}
              render={({ field }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      onBlur={() =>
                        handleBlur('feasibilityStudiesCanStart.value', false)
                      }
                      fullWidth
                    >
                      {yesMaybeNoOtherOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(`dropdown_options.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.feasibility_studies_can_start',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.feasibilityStudiesCanStart && (
              <FormHelperText>
                {errors.feasibilityStudiesCanStart?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherOptionChosen(feasibilityStudiesCanStart) && (
          <Grid item xs={12}>
            <Controller
              name="feasibilityStudiesCanStart.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      value={getValue(field.value, currentLanguage)}
                      error={!!error}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() =>
                        handleBlur('feasibilityStudiesCanStart.details')
                      }
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.feasibility_studies_can_start_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.priorityFactsheet}>
            <Controller
              name="priorityFactsheet.value"
              control={control}
              defaultValue={defaultValues.priorityFactsheet.value}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      error={!!error}
                      onBlur={() =>
                        handleBlur('priorityFactsheet.value', false)
                      }
                      fullWidth
                    >
                      {yesWithNamesNoOtherOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(`dropdown_options.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.priority_factsheet',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.priorityFactsheet && (
              <FormHelperText>
                {errors.priorityFactsheet?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherOptionThanNoChosen(priorityFactsheet) && (
          <Grid item xs={12}>
            <Controller
              name="priorityFactsheet.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      error={!!error}
                      value={getValue(field.value, currentLanguage)}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() => handleBlur('priorityFactsheet.details')}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.priority_factsheet_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.theme}>
            <Controller
              name="theme.value"
              control={control}
              defaultValue={defaultValues.theme.value}
              render={({ field }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      multiple
                      onBlur={() => handleBlur('theme.value', false)}
                      fullWidth
                    >
                      {themes.map((theme) => {
                        return (
                          <MenuItem key={theme.name} value={theme._id}>
                            {t(`themes.${theme.name}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.themes',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.theme && (
              <FormHelperText>{errors.theme?.message as string}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherThemeChosen(themes, theme) && (
          <Grid item xs={12}>
            <Controller
              name="theme.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      value={getValue(field.value, currentLanguage)}
                      error={!!error}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() => handleBlur('theme.details')}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.theme_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            name="location"
            control={control}
            defaultValue={defaultValues.location}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <TextField
                    {...field}
                    value={getValue(field.value, currentLanguage)}
                    error={!!error}
                    helperText={error?.message}
                    fullWidth
                    onBlur={() => handleBlur('location')}
                  />
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.location',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
            {t(
              'investment_projects_page.project_creation_dialog.location_attachments',
            )}
          </Typography>
          <Controller
            name="locationAttachments"
            control={control}
            render={({ field }) => (
              <ProjectDetailsPanelLinkUpload
                fieldName={field.name}
                attachments={field.value}
                handleDelete={handleDeleteAttachment}
                openDialog={() => setFieldKeyAttachments('locationAttachments')}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="description"
            control={control}
            defaultValue={defaultValues.description}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <TextField
                    {...field}
                    value={getValue(field.value, currentLanguage)}
                    error={!!error}
                    multiline
                    rows={4}
                    helperText={error?.message}
                    fullWidth
                    onBlur={() => handleBlur('description')}
                  />
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.description',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
            {t(
              'investment_projects_page.project_creation_dialog.description_attachments',
            )}
          </Typography>
          <Controller
            name="descriptionAttachments"
            control={control}
            render={({ field }) => (
              <ProjectDetailsPanelLinkUpload
                fieldName={field.name}
                attachments={field.value}
                handleDelete={handleDeleteAttachment}
                openDialog={() =>
                  setFieldKeyAttachments('descriptionAttachments')
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.donorIsRequired}>
            <Controller
              name="donorIsRequired.value"
              control={control}
              defaultValue={defaultValues.donorIsRequired.value}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      error={!!error}
                      onBlur={() => handleBlur('donorIsRequired.value', false)}
                      fullWidth
                    >
                      {yesNoOtherOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(`dropdown_options.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.donor_required',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.donorIsRequired && (
              <FormHelperText>
                {errors.donorIsRequired?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherOptionChosen(donorIsRequired) && (
          <Grid item xs={12}>
            <Controller
              name="donorIsRequired.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      error={!!error}
                      value={getValue(field.value, currentLanguage)}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() => handleBlur('donorIsRequired.details')}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.donor_required_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.ownFundingAvailable}>
            <Controller
              name="ownFundingAvailable.value"
              control={control}
              defaultValue={defaultValues.ownFundingAvailable.value}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      error={!!error}
                      onBlur={() =>
                        handleBlur('ownFundingAvailable.value', false)
                      }
                      fullWidth
                    >
                      {ownFundingOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(`dropdown_options.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.own_funding_available',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.ownFundingAvailable && (
              <FormHelperText>
                {errors.ownFundingAvailable?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {ownFundingIsPresented(ownFundingAvailable) && (
          <Grid item xs={12}>
            <Controller
              name="ownFundingAvailable.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      error={!!error}
                      value={getValue(field.value, currentLanguage)}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() => handleBlur('ownFundingAvailable.details')}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.own_funding_available_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.fundingDiscussedWithDonors}>
            <Controller
              name="fundingDiscussedWithDonors.value"
              control={control}
              defaultValue={defaultValues.fundingDiscussedWithDonors.value}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      error={!!error}
                      onBlur={() =>
                        handleBlur('fundingDiscussedWithDonors.value', false)
                      }
                      fullWidth
                    >
                      {yesWithNamesNoOtherOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(`dropdown_options.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.funding_discussed_with_donors',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.fundingDiscussedWithDonors && (
              <FormHelperText>
                {errors.fundingDiscussedWithDonors?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherOptionThanNoChosen(fundingDiscussedWithDonors) && (
          <Grid item xs={12}>
            <Controller
              name="fundingDiscussedWithDonors.details"
              control={control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <FormControlLabel
                    sx={commonFormControlLabelStyles}
                    control={
                      <TextField
                        {...field}
                        error={!!error}
                        value={getValue(field.value, currentLanguage)}
                        helperText={error?.message}
                        multiline
                        rows={4}
                        fullWidth
                        onBlur={() =>
                          handleBlur('fundingDiscussedWithDonors.details')
                        }
                      />
                    }
                    label={
                      <Typography
                        sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}
                      >
                        {t(
                          'investment_projects_page.project_creation_dialog.funding_discussed_clarify',
                        )}
                      </Typography>
                    }
                    labelPlacement="top"
                  />
                );
              }}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.projectPhasing}>
            <Controller
              name="projectPhasing.value"
              control={control}
              defaultValue={defaultValues.projectPhasing.value}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      onBlur={() => handleBlur('projectPhasing.value', false)}
                      error={!!error}
                      fullWidth
                    >
                      {project_phases.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(`dropdown_options.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.project_phasing',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.projectPhasing && (
              <FormHelperText>
                {errors.projectPhasing?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherOptionChosen(projectPhasing) && (
          <Grid item xs={12}>
            <Controller
              name="projectPhasing.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      error={!!error}
                      value={getValue(field.value, currentLanguage)}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() => handleBlur('projectPhasing.details')}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.project_phasing_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.implementationCanStart}>
            <Controller
              name="implementationCanStart.value"
              control={control}
              defaultValue={defaultValues.implementationCanStart.value}
              render={({ field }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      onBlur={() =>
                        handleBlur('implementationCanStart.value', false)
                      }
                      fullWidth
                    >
                      {yesMaybeNoOtherOptions.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(`dropdown_options.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.implementation_can_start',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.implementationCanStart && (
              <FormHelperText>
                {errors.implementationCanStart?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherOptionChosen(implementationCanStart) && (
          <Grid item xs={12}>
            <Controller
              name="implementationCanStart.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      value={getValue(field.value, currentLanguage)}
                      error={!!error}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() =>
                        handleBlur('implementationCanStart.details')
                      }
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.implementation_can_start_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth error={!!errors.urgencyLevel}>
            <Controller
              name="urgencyLevel.value"
              control={control}
              defaultValue={defaultValues.urgencyLevel.value}
              render={({ field }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select
                      {...field}
                      onBlur={() => handleBlur('urgencyLevel.value', false)}
                      fullWidth
                    >
                      {urgencyLevels.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {t(`dropdown_options.${option}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.urgency_level',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
            {errors.urgencyLevel && (
              <FormHelperText>
                {errors.urgencyLevel?.message as string}
              </FormHelperText>
            )}
          </FormControl>
        </Grid>
        {isOtherOptionChosen(urgencyLevel) && (
          <Grid item xs={12}>
            <Controller
              name="urgencyLevel.details"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <TextField
                      {...field}
                      value={getValue(field.value, currentLanguage)}
                      error={!!error}
                      helperText={error?.message}
                      multiline
                      rows={4}
                      fullWidth
                      onBlur={() => handleBlur('urgencyLevel.details')}
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.urgency_level_clarify',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              )}
            />
          </Grid>
        )}
        {/*<Grid item xs={12}>*/}
        {/*  <Controller*/}
        {/*    name="shortListScore"*/}
        {/*    control={control}*/}
        {/*    defaultValue={defaultValues.shortListScore}*/}
        {/*    render={({ field, fieldState: { error } }) => (*/}
        {/*      <FormControlLabel*/}
        {/*        sx={commonFormControlLabelStyles}*/}
        {/*        control={*/}
        {/*          <TextField*/}
        {/*            {...field}*/}
        {/*            type="number"*/}
        {/*            disabled*/}
        {/*            error={!!error}*/}
        {/*            helperText={error?.message}*/}
        {/*            fullWidth*/}
        {/*            onBlur={() => handleBlur('shortListScore', false)}*/}
        {/*          />*/}
        {/*        }*/}
        {/*        label={*/}
        {/*          <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>*/}
        {/*            {t(*/}
        {/*              'investment_projects_page.project_creation_dialog.shortlist_score',*/}
        {/*            )}*/}
        {/*          </Typography>*/}
        {/*        }*/}
        {/*        labelPlacement="top"*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</Grid>*/}
      </Grid>
      <LinkDialogCreation
        open={Boolean(fieldKeyAttachments)}
        onClose={() => setFieldKeyAttachments(null)}
        onSubmit={(value) => {
          const newAttachment = { name: value.name, url: value.url };

          fieldKeyAttachments &&
            handleAttachmentUpload(fieldKeyAttachments, [
              ...getValues(fieldKeyAttachments),
              newAttachment,
            ]);
        }}
      />
    </form>
  );
};

export default ProjectDetailsForm;
