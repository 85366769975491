export const queryKeys: Record<string, string> = {
  // admin
  getAdminMessage: 'getAdminMessage',

  // current user
  getCurrentUser: 'getCurrentUser',

  // organization
  getOrganizations: 'getOrganizations',
  getOrganizationById: 'getOrganizationById',

  // request
  getAllRequestsByOrganizationId: 'getAllRequestsByOrganizationId',

  // general information template
  getGeneralInformationTemplate: 'getGeneralInformationTemplate',
  updateGeneralInformationTemplate: 'updateGeneralInformationTemplate',

  // potential failures template
  getPotentialFailuresTemplate: 'getPotentialFailuresTemplate',
  updatePotentialFailuresTemplate: 'updatePotentialFailuresTemplate',

  // composition
  getCompositionData: 'getCompositionData',
  updateCompositionData: 'updateCompositionData',

  // recommended-priority
  getRecommendedPriority: 'getRecommendedPriority',

  // risk-matrix
  getRiskMatrixData: 'getRiskMatrixData',
  updateRiskMatrixData: 'updateRiskMatrixData',

  // attachments
  uploadAttachments: 'uploadAttachments',
  getAttachmentUrl: 'getAttachmentUrl',

  // comments
  getCommentsByUniqueId: 'getCommentsByUniqueId',
  createCommentByUniqueId: 'createCommentByUniqueId',
  updateCommentById: 'updateCommentById',
  deleteCommentById: 'deleteCommentById',

  // investment projects
  getInvestmentProjectMetadata: 'getInvestmentProjectMetadata',
  getInvestmentProjects: 'getInvestmentProjects',
  getInvestmentProject: 'getInvestmentProject',
  createInvestmentProject: 'createInvestmentProject',
  patchInvestmentProject: 'patchInvestmentProject',
  deleteInvestmentProject: 'deleteInvestmentProject',
  // services
  getServices: 'getServices',

  // categories
  getCategories: 'getCategories',

  // themes
  getThemes: 'getThemes',

  // risk scores
  getRiskScoresByProcessId: 'getRiskScoresByProcessId',

  // report
  generateReport: 'generateReport',

  // utility assessment
  updateUtilityAssessmentFormByOrganizationId:
    'updateUtilityAssessmentFormByOrganizationId',
  getInstitutionalAndEfficiencyAssessmentFormByOrganizationId:
    'getInstitutionalAndEfficiencyAssessmentFormByOrganizationId',
  getTechnicalAndEfficiencyAssessmentFormByOrganizationId:
    'getTechnicalAndEfficiencyAssessmentFormByOrganizationId',
  getCommercialAndFinancialAssessmentFormByOrganizationId:
    'getCommercialAndFinancialAssessmentFormByOrganizationId',

  // shortlist criteria
  getShortListCriteriaFormByOrganizationId:
    'getShortListCriteriaFormByOrganizationId',
  updateShortListCriteriaFormByOrganizationId:
    'updateShortListCriteriaFormByOrganizationId',

  // shortlist criteria for project
  getShortListCriteriaForProjectByProjectId:
    'getShortListCriteriaForProjectByProjectId',
  updateShortListCriteriaForProjectByProjectId:
    'updateShortListCriteriaForProjectByProjectId',

  // common
  getToken: 'getToken',
};
