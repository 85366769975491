export const COLUMNS = [
  'dynamic_water_level',
  'flow_rate_of_the_well',
  'brand_of_pumps',
  'rnom_engine',
  'pressure_at_the_zero_mark_of_the_well',
  'water_rise_per_year',
  'amount_of_consumed_electricity',
  'number_of_consumers',
  'number_of_consumers_of_the_population',
];
