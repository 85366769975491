import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';

import {
  StyledChip,
  StyledFileUploadWrapper,
} from 'components/link-upload/styles';

import { ReactComponent as UploadFileIcon } from 'assets/upload-file-icon.svg';

interface ProjectDetailsPanelLinkUploadProps {
  fieldName: string;
  attachments: [{ name: string; url: string }];
  handleDelete: (fieldName: string, attachmentUrl: string) => void;
  openDialog: () => void;
}

const ProjectDetailsPanelLinkUpload: FC<ProjectDetailsPanelLinkUploadProps> = ({
  fieldName,
  attachments,
  handleDelete,
  openDialog,
}) => {
  const { t } = useTranslation();

  return (
    <StyledFileUploadWrapper>
      {attachments?.map(({ url }, index) => (
        <StyledChip
          key={url}
          variant="outlined"
          deleteIcon={<CloseIcon />}
          onDelete={() => handleDelete(fieldName, url)}
          onClick={() => window.open(url, '_blank')}
          label={`${t('link_dialog_creation.file', { index: index + 1 })}`}
        />
      ))}

      <Button
        component="label"
        startIcon={<UploadFileIcon />}
        onClick={() => openDialog()}
      >
        {t('attach_document')}
      </Button>
    </StyledFileUploadWrapper>
  );
};

export default ProjectDetailsPanelLinkUpload;
