import { Box, Typography, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledStepTitle = styled(Typography)({
  fontWeight: 700,
  backgroundColor: theme.palette.grey[100],
  padding: 8,
  borderRadius: 4,
  marginBottom: 24,
});

export const StyledExplanationBoxWrapper = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});
