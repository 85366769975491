import React, { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { renderTooltipTitle } from 'pages/investment-project-page/tab-panels/project-details-panel/categories-tooltip';

import { StyledLightTooltip } from 'components/investment-plan-layout/styles';

import { getValue } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { commonFormControlLabelStyles } from '../styles';
import { ProjectCreationFormProps } from './types';
import {
  isOtherOptionThanNoChosen,
  isOtherServiceChosen,
  isOtherTargetedBenefitsChosen,
} from './utils';

export const ProjectCreationForm: FC<ProjectCreationFormProps> = ({
  investmentProjectMetadata,
}) => {
  const { currentLanguage } = useIsEnLanguage();
  const { t } = useTranslation();
  const {
    services,
    targeted_benefits,
    categories,
    yesWithStudyNoOtherOptions,
    yesWithNamesNoOtherOptions,
  } = investmentProjectMetadata;
  const {
    control,
    getValues,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const defaultValues = getValues();

  const [service, feasibilityStudies, targetedBenefits, priorityFactsheet] =
    watch([
      'service',
      'feasibilityStudies',
      'targetedBenefits',
      'priorityFactsheet',
    ]);

  useEffect(() => {
    if (!isOtherServiceChosen(services, service)) {
      setValue('serviceDetails', null);
    }
  }, [service, services, setValue]);

  useEffect(() => {
    if (!isOtherOptionThanNoChosen(feasibilityStudies)) {
      setValue('feasibilityStudiesDetails', null);
    }
  }, [feasibilityStudies, setValue]);

  useEffect(() => {
    if (!isOtherOptionThanNoChosen(priorityFactsheet)) {
      setValue('priorityFactsheetDetails', null);
    }
  }, [priorityFactsheet, setValue]);

  useEffect(() => {
    if (!isOtherTargetedBenefitsChosen(targetedBenefits)) {
      setValue('targetedBenefitsDetails', null);
    }
  }, [setValue, targetedBenefits]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name="name"
          control={control}
          defaultValue={defaultValues.name}
          render={({ field, fieldState: { error } }) => (
            <FormControlLabel
              sx={commonFormControlLabelStyles}
              control={
                <TextField
                  {...field}
                  value={getValue(field.value, currentLanguage)}
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                />
              }
              label={
                <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                  {t('investment_projects_page.project_creation_dialog.name')}
                </Typography>
              }
              labelPlacement="top"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="service"
          control={control}
          defaultValue={defaultValues.service}
          render={({ field }) => (
            <FormControlLabel
              sx={commonFormControlLabelStyles}
              control={
                <RadioGroup {...field} name="service-radio-buttons-group">
                  {services.map((service) => (
                    <FormControlLabel
                      key={service.name}
                      value={service._id}
                      control={<Radio />}
                      label={t(`services.${service.name}`)}
                    />
                  ))}
                </RadioGroup>
              }
              label={
                <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                  {t(
                    'investment_projects_page.project_creation_dialog.service',
                  )}
                </Typography>
              }
              labelPlacement="top"
            />
          )}
        />
      </Grid>
      {isOtherServiceChosen(services, service) && (
        <Grid item xs={12}>
          <Controller
            name="serviceDetails"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <TextField
                    {...field}
                    error={!!error}
                    value={getValue(field.value, currentLanguage)}
                    helperText={error?.message}
                    multiline
                    rows={4}
                    fullWidth
                  />
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.service_clarify',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.category}>
          <Controller
            name="category"
            control={control}
            defaultValue={defaultValues.category}
            render={({ field }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <Select {...field} fullWidth>
                    {categories.map((category) => {
                      return (
                        <MenuItem key={category.name} value={category._id}>
                          {t(`categories.${category.name}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.category',
                      )}
                    </Typography>
                    <StyledLightTooltip
                      title={renderTooltipTitle(t)}
                      arrow
                      placement="right-end"
                    >
                      <IconButton sx={{ ml: 1 }}>
                        <InfoOutlinedIcon sx={{ color: 'graymodern.400' }} />
                      </IconButton>
                    </StyledLightTooltip>
                  </Box>
                }
                labelPlacement="top"
              />
            )}
          />
          {errors.category && (
            <FormHelperText>
              {errors.category?.message as string}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="totalCost"
          control={control}
          defaultValue={defaultValues.totalCost}
          render={({ field, fieldState: { error } }) => (
            <FormControlLabel
              sx={commonFormControlLabelStyles}
              control={
                <TextField
                  {...field}
                  type="number"
                  error={!!error}
                  helperText={error?.message}
                  fullWidth
                />
              }
              label={
                <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                  {t(
                    'investment_projects_page.project_creation_dialog.total_cost',
                  )}
                </Typography>
              }
              labelPlacement="top"
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.targetedBenefits}>
          <Controller
            name="targetedBenefits"
            control={control}
            defaultValue={defaultValues.targetedBenefits}
            render={({ field }) => {
              return (
                <FormControlLabel
                  sx={commonFormControlLabelStyles}
                  control={
                    <Select {...field} multiple fullWidth>
                      {targeted_benefits.map((benefit) => {
                        return (
                          <MenuItem key={benefit} value={benefit}>
                            {t(`targeted_benefits.${benefit}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  }
                  label={
                    <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                      {t(
                        'investment_projects_page.project_creation_dialog.targeted_benefits',
                      )}
                    </Typography>
                  }
                  labelPlacement="top"
                />
              );
            }}
          />
          {errors.targetedBenefits && (
            <FormHelperText>
              {errors.targetedBenefits?.message as string}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      {isOtherTargetedBenefitsChosen(targetedBenefits) && (
        <Grid item xs={12}>
          <Controller
            name="targetedBenefitsDetails"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <TextField
                    {...field}
                    value={getValue(field.value, currentLanguage)}
                    error={!!error}
                    helperText={error?.message}
                    multiline
                    rows={4}
                    fullWidth
                  />
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.targeted_benefits_clarify',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.feasibilityStudies}>
          <Controller
            name="feasibilityStudies"
            control={control}
            defaultValue={defaultValues.feasibilityStudies}
            render={({ field }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <Select {...field} fullWidth>
                    {yesWithStudyNoOtherOptions.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {t(`dropdown_options.${option}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.feasibility_studies',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
          {errors.feasibilityStudies && (
            <FormHelperText>
              {errors.feasibilityStudies?.message as string}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      {isOtherOptionThanNoChosen(feasibilityStudies) && (
        <Grid item xs={12}>
          <Controller
            name="feasibilityStudiesDetails"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <TextField
                    {...field}
                    value={getValue(field.value, currentLanguage)}
                    error={!!error}
                    helperText={error?.message}
                    multiline
                    rows={4}
                    fullWidth
                  />
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.feasibility_studies_clarify',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FormControl fullWidth error={!!errors.priorityFactsheet}>
          <Controller
            name="priorityFactsheet"
            control={control}
            defaultValue={defaultValues.priorityFactsheet}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <Select {...field} error={!!error} fullWidth>
                    {yesWithNamesNoOtherOptions.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {t(`dropdown_options.${option}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.priority_factsheet',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
          {errors.priorityFactsheet && (
            <FormHelperText>
              {errors.priorityFactsheet?.message as string}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      {isOtherOptionThanNoChosen(priorityFactsheet) && (
        <Grid item xs={12}>
          <Controller
            name="priorityFactsheetDetails"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormControlLabel
                sx={commonFormControlLabelStyles}
                control={
                  <TextField
                    {...field}
                    value={getValue(field.value, currentLanguage)}
                    error={!!error}
                    helperText={error?.message}
                    multiline
                    rows={4}
                    fullWidth
                  />
                }
                label={
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                    {t(
                      'investment_projects_page.project_creation_dialog.priority_factsheet_clarify',
                    )}
                  </Typography>
                }
                labelPlacement="top"
              />
            )}
          />
        </Grid>
      )}
    </Grid>
  );
};
