import { Box, styled } from '@mui/material';

import { PageLoaderProps } from './types';

export const StyledLoaderWrapper = styled(Box)<PageLoaderProps>(
  ({ height }) => ({
    height: height || '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);
