import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { queryClient } from 'react-query/queryClient';

import { UtilityAssessmentForms } from 'pages/utility-assessment-page/types';

import { UserModel } from 'models/user.model';

import { utilityAssessmentService } from 'services/utility-assessment.service';

import { AppContext } from 'context';

import { UseUpdateUtilityAssessmentFormByOrganizationId } from './types';

export const useUpdateUtilityAssessmentFormByOrganizationId = (
  updateKeyOnSuccess: string,
  updateDataByKey: UtilityAssessmentForms,
) => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({
      data,
      organizationId,
    }: UseUpdateUtilityAssessmentFormByOrganizationId) =>
      await utilityAssessmentService.updateUtilityAssessmentFormByOrganizationId(
        auth,
        organizationId,
        data,
      ),
    {
      mutationKey: [
        queryKeys.updateUtilityAssessmentFormByOrganizationId,
        updateKeyOnSuccess,
        updateDataByKey,
      ],
      onSuccess: (data) =>
        queryClient.setQueryData([updateKeyOnSuccess], data?.[updateDataByKey]),
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};
