import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';

import { UserModel } from 'models/user.model';

import { shortlistCriteriaService } from 'services/shortlist-criteria.service';

import { AppContext } from 'context';

import {
  UseGetShortListCriteriaForProjectByProjectId,
  UseGetShortListCriteriaFormByOrganizationId,
} from './types';

export const useGetShortListCriteriaFormByOrganizationId = (
  organizationId?: string,
): UseGetShortListCriteriaFormByOrganizationId => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError, isFetching } = useQuery(
    queryKeys.getShortListCriteriaFormByOrganizationId,
    () =>
      shortlistCriteriaService.getShortListCriteriaFormByOrganizationId(
        auth,
        organizationId,
      ),
    {
      enabled: !!organizationId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
  };
};

export const useGetShortListCriteriaForProjectByProjectId = (
  projectId?: string,
): UseGetShortListCriteriaForProjectByProjectId => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError, isFetching } = useQuery(
    queryKeys.getShortListCriteriaForProjectByProjectId,
    () =>
      shortlistCriteriaService.getShortListCriteriaForProjectByProjectId(
        auth,
        projectId,
      ),
    {
      enabled: !!projectId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
  };
};
