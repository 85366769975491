import { FC } from 'react';

import { Grid } from '@mui/material';

import { InvestmentPlanSection } from 'components/investment-plan-section/investment-plan-section';

export const OrganizationBlockList: FC = () => {
  return (
    <Grid container spacing={3}>
      <InvestmentPlanSection />

      {/*{processes.map((process) => (*/}
      {/*  <Grid key={process._id} item xs={12}>*/}
      {/*    <ProcessItem process={process} />*/}
      {/*  </Grid>*/}
      {/*))}*/}
    </Grid>
  );
};
