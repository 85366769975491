import { IconButton, styled } from '@mui/material';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: 8,
  top: 8,
  color: theme.palette.grey[500],
}));

export const commonFormControlLabelStyles = {
  alignItems: 'flex-start',
  mx: 'unset',
  width: '100%',
};
