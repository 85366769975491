import { Tabs, styled } from '@mui/material';

import { StyledTabsProps } from './types';

export const StyledTabs = styled(Tabs, {
  shouldForwardProp: (prop) => prop !== 'tabVariant',
})<StyledTabsProps>(({ tabVariant, theme }) => {
  const defaultTabStyles = {
    '& .MuiTabs-indicator': {
      display: 'block',
    },
    '& .MuiTab-root': {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      display: 'inline-flex',
      justifyContent: 'center',
      textAlign: 'unset',
      color: theme.palette.text.primary,
      transition: 'all 0.3s ease',
      '&.Mui-selected': {
        color: theme.palette.primary.main,
      },
    },
  };

  const buttonTabStyles = {
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .MuiTab-root': {
      display: 'flex',
      justifyContent: 'flex-start',
      textAlign: 'left',
      color: theme.palette.secondary.contrastText,
      transition: 'all 0.3s ease',
      borderBottom: 'none',
      '&.Mui-selected': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '4px',
      },
    },
  };

  return tabVariant === 'button' ? buttonTabStyles : defaultTabStyles;
});
