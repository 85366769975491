import { Outlet, useLocation, useParams } from 'react-router-dom';
import { InvestmentPlanRoutes, Routes } from 'routes/constants';

import { Box } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

import { Header } from 'components/header/header';

export const Layout = () => {
  const { isLoading, error } = useAuth0();
  const { pathname } = useLocation();
  const { organizationId } = useParams();

  const investmentProjectsPagePath = `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.INVESTMENT_PLAN}/${InvestmentPlanRoutes.INVESTMENT_PROJECTS}`;

  const bgcolor =
    pathname === investmentProjectsPagePath ? 'unset' : 'background.default';

  return (
    <Box>
      <Header />

      <Box sx={{ bgcolor, minHeight: 'calc(100vh - 81px)' }} component="main">
        {isLoading && <div>Loading...</div>}
        {error && <div>Oops... {error.message}</div>}
        {!isLoading && !error && <Outlet />}
      </Box>
    </Box>
  );
};
