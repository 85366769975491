import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import {
  InvestmentPlanRoutes,
  Routes,
  UtilityAssessmentRoutes,
} from 'routes/constants';

import { Box, Grid } from '@mui/material';

import { InvestmentPlanLayout } from 'components/investment-plan-layout/investment-plan-layout';
import { PaperLayout } from 'components/paper-layout/paper-layout';
import { Tabs } from 'components/tabs/tabs';

import { UtilityAssessmentContextProvider } from './context';

export const UtilityAssessmentPage: FC = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();

  const commonPath = `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.INVESTMENT_PLAN}/${InvestmentPlanRoutes.UTILITY_ASSESSMENT}`;

  const getUtilityAssessmentTabs = [
    // {
    //   label: t(
    //     'utility_assessment_page.institutional_and_efficiency_assessment.title',
    //   ),
    //   value: UtilityAssessmentRoutes.INSTITUTIONAL_AND_EFFICIENCY_ASSESSMENT,
    //   path: `${commonPath}/${UtilityAssessmentRoutes.INSTITUTIONAL_AND_EFFICIENCY_ASSESSMENT}`,
    // },
    {
      label: t(
        'utility_assessment_page.technical_and_efficiency_assessment.title',
      ),
      value: UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT,
      path: `${commonPath}/${UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}`,
    },
    // {
    //   label: t(
    //     'utility_assessment_page.commercial_and_financial_assessment.title',
    //   ),
    //   value: UtilityAssessmentRoutes.COMMERCIAL_AND_FINANCIAL_ASSESSMENT,
    //   path: `${commonPath}/${UtilityAssessmentRoutes.COMMERCIAL_AND_FINANCIAL_ASSESSMENT}`,
    // },
  ];

  return (
    <UtilityAssessmentContextProvider>
      <InvestmentPlanLayout sectionName="utility_assessment_page" renderTooltip>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <PaperLayout borderRadius={4}>
              <Tabs
                tabsData={getUtilityAssessmentTabs}
                defaultValue={
                  UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT
                }
              />

              <Box>
                <Outlet />
              </Box>
            </PaperLayout>
          </Grid>
        </Grid>
      </InvestmentPlanLayout>
    </UtilityAssessmentContextProvider>
  );
};
