import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { DEFAULT_SCORE_VALUE } from 'pages/investment-project-page/tab-panels/project-evaluation-panel/project-evaluation-form/constants';
import { StyledScoreWrapper } from 'pages/investment-project-page/tab-panels/project-evaluation-panel/project-evaluation-form/styles';
import { SCORE_BACKGROUND_COLOR } from 'pages/shortlist-criteria-page/constants';

export const ScoreCellRenderer: FC<ICellRendererParams> = (props) => {
  const { t } = useTranslation();

  const {
    value: { options, value },
  } = props;

  const foundOptionKey = options.find(
    ({ score }: { score: number }) => score === value,
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      {!value ? (
        <>
          <StyledScoreWrapper bgcolor="grey.300">
            {DEFAULT_SCORE_VALUE}
          </StyledScoreWrapper>

          <Typography>{t('dropdown_options.not_assessed')}</Typography>
        </>
      ) : (
        <>
          <StyledScoreWrapper bgcolor={SCORE_BACKGROUND_COLOR[Number(value)]}>
            {value}
          </StyledScoreWrapper>

          <Typography>
            {t(`dropdown_options.${foundOptionKey.key || 'not_assessed'}`)}
          </Typography>
        </>
      )}
    </Box>
  );
};
