import axios from 'axios';

import { Auth0ContextInterface } from '@auth0/auth0-react';

import { UserModel } from 'models/user.model';

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const instance = axios.create({
  baseURL: apiServerUrl,
});

export const logoutOnInvalidToken = async (
  auth: Auth0ContextInterface<UserModel>,
) => {
  try {
    return await auth.getAccessTokenSilently();
  } catch (err) {
    auth.logout({
      logoutParams: { returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL },
    });
  }
};

// https://stackoverflow.com/questions/73729085/fetch-an-auth0-access-token-asynchronously-from-within-react-query-function
export const axiosGet = async <T>(
  auth: Auth0ContextInterface<UserModel>,
  url: string,
  config = {},
): Promise<T> => {
  const token = await logoutOnInvalidToken(auth);
  const response = await instance.get(url, {
    ...config,
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data as T;
};

export const axiosPost = async <T, D>(
  auth: Auth0ContextInterface<UserModel>,
  url: string,
  data: D,
  headers = {},
): Promise<T> => {
  const token = await logoutOnInvalidToken(auth);

  const response = await instance.post(url, data, {
    headers: { Authorization: `Bearer ${token}`, ...headers },
  });

  return response.data as T;
};

export const axiosUpdate = async <T, D>(
  auth: Auth0ContextInterface<UserModel>,
  url: string,
  data: D,
): Promise<T> => {
  const token = await logoutOnInvalidToken(auth);

  const response = await instance.patch(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data as T;
};

export const axiosPut = async <T, D>(
  auth: Auth0ContextInterface<UserModel>,
  url: string,
  data: D,
): Promise<T> => {
  const token = await logoutOnInvalidToken(auth);

  const response = await instance.put(url, data, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data as T;
};

export const axiosDelete = async (
  auth: Auth0ContextInterface<UserModel>,
  url: string,
) => {
  const token = await logoutOnInvalidToken(auth);

  const response = await instance.delete(url, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response.data;
};
