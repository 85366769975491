import { FC } from 'react';
import { Link } from 'react-router-dom';

import Tab from '@mui/material/Tab';

import { useCurrentTab } from './hooks';
import { StyledTabs } from './styles';
import { TabsProps } from './types';

export const Tabs: FC<TabsProps> = ({
  defaultValue,
  tabsData,
  searchParam,
  orientation = 'horizontal',
  variant = 'default',
  validTabs = [],
}) => {
  const routeMatch = useCurrentTab(validTabs, searchParam);

  return (
    <StyledTabs
      value={routeMatch || defaultValue}
      variant="fullWidth"
      orientation={orientation}
      tabVariant={variant}
    >
      {tabsData.map(({ label, path, value, icon, iconPosition = 'start' }) => (
        <Tab
          iconPosition={iconPosition}
          icon={icon}
          label={label}
          value={value}
          key={label}
          to={path}
          component={Link}
        />
      ))}
    </StyledTabs>
  );
};
