export const COLUMNS = [
  'brand_of_pumps',
  'productivity',
  'pressure',
  'support_suction',
  'output_pressure_day',
  'output_pressure_night',
  'water_pumping',
  'electricity_consumed_for_the_year',
  'number_of_subscribers',
  'number_of_consumers_of_the_population',
];
