import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

export const LinkCellRenderer: FC<ICellRendererParams> = (props) => {
  const { currentLanguage } = useIsEnLanguage();

  return (
    <Link to={props.data._id}>
      {typeof props.value === 'object'
        ? props.value[currentLanguage]
        : props.value}
    </Link>
  );
};
