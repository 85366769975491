import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import { Question, Step } from 'models/project-factsheet-form.model';

import { useProjectFactsheetForm } from '../hooks/useProjectFactsheetForm';

import { TRANSLATION_KEY } from '../constants';
import { ProjectFactsheetFormProps } from './types';

export const ProjectFactsheetForm = ({
  data,
  onUpdateForm,
}: ProjectFactsheetFormProps) => {
  const { t } = useTranslation();
  const { renderQuestions, renderSteps } =
    useProjectFactsheetForm(onUpdateForm);

  const { descriptionOfTheProject, quickScanResults } = data;

  const renderQuickScanResultsSection = (steps: Record<string, Step>) => (
    <Grid item xs={12}>
      <Typography sx={{ fontWeight: 800, fontSize: 18, mb: 3 }}>
        {t(`${TRANSLATION_KEY}.quick_scan_results.title`)}
      </Typography>

      {steps && renderSteps(steps, 'quickScanResults')}
    </Grid>
  );

  const renderDescriptionSection = (questions: Record<string, Question>) => (
    <Grid item xs={12}>
      <Typography sx={{ fontWeight: 800, fontSize: 18, mb: 3 }}>
        {t(`${TRANSLATION_KEY}.description_of_the_project.title`)}
      </Typography>

      {questions &&
        renderQuestions(
          questions,
          'descriptionOfTheProject',
          'descriptionOfTheProject',
        )}
    </Grid>
  );

  return (
    <form>
      <Grid container spacing={3} p={4}>
        {'questions' in descriptionOfTheProject &&
          renderDescriptionSection(descriptionOfTheProject.questions)}

        {'steps' in quickScanResults &&
          renderQuickScanResultsSection(quickScanResults.steps)}
      </Grid>
    </form>
  );
};
