import { CircularProgress, Typography } from '@mui/material';

import { StyledLoaderWrapper } from './styles';
import { PageLoaderProps } from './types';

export const PageLoader = ({ height = '100vh' }: PageLoaderProps) => (
  <StyledLoaderWrapper height={height}>
    <CircularProgress sx={{ color: 'primary.main' }} />

    <Typography sx={{ fontSize: 14, fontWeight: 600, mt: 1.5 }}>
      Loading...
    </Typography>
  </StyledLoaderWrapper>
);
