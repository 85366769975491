import { useUpdateShortListCriteriaForProjectByProjectId } from 'hooks/mutations/shortlist-criteria/shortlist-criteria';
import { useGetShortListCriteriaForProjectByProjectId } from 'hooks/queries/shortlist-criteria/shortlist-criteria';
import { FC, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InvestmentProjectRoutes } from 'routes/constants';

import { Box, CircularProgress } from '@mui/material';

import { AppContext } from 'context';

import { ProjectEvaluationForm } from './project-evaluation-form/project-evaluation-form';

export const ProjectEvaluationPanel: FC = () => {
  const { organizationId, investmentProjectId } = useParams();
  const {
    commentsUniquePathId: { setCommentsUniquePathId },
  } = useContext(AppContext);

  const {
    data: shortlistCriteriaForProjectData,
    isLoading: isLoadingShortlistCriteriaForProjectData,
  } = useGetShortListCriteriaForProjectByProjectId(investmentProjectId);
  const { mutateAsync: updateShortListCriteriaForProjectByProjectId } =
    useUpdateShortListCriteriaForProjectByProjectId(investmentProjectId);

  const methods = useForm({ defaultValues: undefined });
  const { watch, reset } = methods;

  const handleUpdateFormOnBlur = async (fieldName: string) => {
    const value = watch(fieldName);

    if (value === null) return;

    const data = {
      path: fieldName,
      value,
    };

    await updateShortListCriteriaForProjectByProjectId({
      id: investmentProjectId,
      data,
    });
  };

  useEffect(() => {
    if (!isLoadingShortlistCriteriaForProjectData) {
      const uniqueCommentsPath = `${investmentProjectId}${InvestmentProjectRoutes.EVALUATION}`;

      setCommentsUniquePathId(uniqueCommentsPath);
    }
  }, [
    isLoadingShortlistCriteriaForProjectData,
    organizationId,
    investmentProjectId,
    setCommentsUniquePathId,
  ]);

  useEffect(() => {
    if (
      !isLoadingShortlistCriteriaForProjectData &&
      shortlistCriteriaForProjectData
    ) {
      reset(shortlistCriteriaForProjectData);
    }
  }, [
    shortlistCriteriaForProjectData,
    isLoadingShortlistCriteriaForProjectData,
    reset,
  ]);

  if (
    isLoadingShortlistCriteriaForProjectData ||
    !shortlistCriteriaForProjectData
  ) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ px: 5 }}>
      <FormProvider {...methods}>
        <form>
          <ProjectEvaluationForm
            data={shortlistCriteriaForProjectData}
            onUpdateFormOnBlur={handleUpdateFormOnBlur}
          />
        </form>
      </FormProvider>
    </Box>
  );
};
