import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'routes/constants';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import { useAuth0 } from '@auth0/auth0-react';

import { CommentsSidebar } from 'components/comments/comments-sidebar/comments-sidebar';

import { UserModel } from 'models/user.model';

import { StyledLayoutContainer } from 'configs/theme';

import { headerHeight } from 'constants/index';

import { StyledLightTooltip } from './styles';
import { InvestmentPlanLayoutProps } from './types';

export const InvestmentPlanLayout = ({
  content,
  sectionName,
  renderTooltip = false,
  children,
}: InvestmentPlanLayoutProps) => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const { user } = useAuth0<UserModel>();
  const navigate = useNavigate();

  const [isOpenedCommentsSidebar, setIsOpenedCommentsSidebar] = useState(false);

  const pathToHome = user?.app_metadata.isAdmin
    ? `/${Routes.ADMIN}/${Routes.ORGANIZATIONS}/${organizationId}`
    : `/${Routes.ORGANIZATIONS}/${organizationId}`;

  const renderTooltipTitle = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
      <Box>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
          {t(`${sectionName}.tooltip.objective.title`)}
        </Typography>

        <Typography sx={{ fontSize: 14 }}>
          {t(`${sectionName}.tooltip.objective.description`)}
        </Typography>
      </Box>

      <Box>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
          {t(`${sectionName}.tooltip.output.title`)}
        </Typography>

        <Typography sx={{ fontSize: 14 }}>
          {t(`${sectionName}.tooltip.output.description`)}
          <br />
          {t(`${sectionName}.tooltip.output.sub_description`, '')}
        </Typography>
      </Box>
    </Box>
  );

  const renderContentElement = () => (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          color="primary"
          sx={{ mr: 2 }}
          onClick={() => navigate(pathToHome ?? '/')}
        >
          <ArrowBackRoundedIcon />
        </IconButton>

        <Typography sx={{ fontWeight: 700 }}>
          {t('shortlist_criteria_page.back_to_home')}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ ml: 7, fontSize: 24, fontWeight: 800 }}>
          {t(`home_screen.investment_plan.${sectionName}`)}
        </Typography>

        {renderTooltip && (
          <StyledLightTooltip
            title={renderTooltipTitle()}
            arrow
            placement="right-end"
          >
            <IconButton sx={{ ml: 1 }}>
              <InfoOutlinedIcon sx={{ color: 'graymodern.400' }} />
            </IconButton>
          </StyledLightTooltip>
        )}
      </Box>
    </>
  );

  return (
    <Grid
      container
      sx={{ minHeight: `calc(100vh - ${headerHeight + 1}px)` }}
      justifyContent="space-between"
    >
      {!isOpenedCommentsSidebar && <Grid item xs={1} />}

      <Grid item justifyContent="center" xs={9.5} sx={{ px: 4, pb: 4 }}>
        <StyledLayoutContainer>
          {content ?? renderContentElement()}
        </StyledLayoutContainer>

        <>{children}</>
      </Grid>

      <Grid item xs={isOpenedCommentsSidebar ? 2.5 : 0.5}>
        <CommentsSidebar
          getCommentsByUniquePathId
          isOpenedCommentsSidebar={isOpenedCommentsSidebar}
          onSetIsOpenedCommentsSidebar={setIsOpenedCommentsSidebar}
        />
      </Grid>
    </Grid>
  );
};
