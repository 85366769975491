import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

import { Box, Typography } from '@mui/material';

import { StyledScore } from './styles';

export const TotalScoreCellRenderer: FC<ICellRendererParams> = (props) => {
  const { valueFormatted } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <StyledScore>
        <Typography>{valueFormatted}</Typography>
      </StyledScore>
    </Box>
  );
};
