import { Box, Grid, styled } from '@mui/material';

import { theme } from 'configs/theme';

import { StyledCriteriaWrapperProps } from './types';

export const StyledCriteriaWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isCriteriaApplied',
})<StyledCriteriaWrapperProps>(({ isCriteriaApplied }) => ({
  padding: 16,
  backgroundColor: isCriteriaApplied ? 'unset' : theme.palette.grey[100],
  border: isCriteriaApplied ? `1px solid ${theme.palette.grey[200]}` : 'unset',
  borderRadius: 4,
}));

export const StyledScoreWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  fontWeight: 700,
  borderRadius: 4,
  minWidth: 24,
  height: 24,
  fontSize: 12,
});

export const StyledWeightWrapper = styled(Grid)({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  width: '100%',
  gap: 8,
});

export const StyledScoreGridWrapper = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: 8,
  borderRadius: 4,
  backgroundColor: theme.palette.background.default,
  width: 180,
});
