import { CSSObject } from '@emotion/react';

import { InputAdornment, TextField, styled } from '@mui/material';

const getCommonStyles = (isTextFieldFocused: boolean): CSSObject => ({
  position: 'absolute',
  cursor: 'pointer',
  bottom: 20,
  opacity: isTextFieldFocused ? 1 : 0,
});

export const AttachmentIconInputAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) =>
    prop !== 'isTextFieldFocused' && prop !== 'isEditingMode',
})<{
  isTextFieldFocused: boolean;
  isEditingMode?: boolean;
}>(({ isTextFieldFocused, isEditingMode }) => ({
  right: isEditingMode ? 5 : 35,
  ...getCommonStyles(isTextFieldFocused),
}));

export const ArrowUpSendIconInputAdornment = styled(InputAdornment, {
  shouldForwardProp: (prop) => prop !== 'isTextFieldFocused',
})<{
  isTextFieldFocused: boolean;
}>(({ isTextFieldFocused }) => ({
  right: 10,
  ...getCommonStyles(isTextFieldFocused),
}));

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isTextFieldFocused',
})<{
  isTextFieldFocused: boolean;
}>(({ isTextFieldFocused }) =>
  isTextFieldFocused
    ? { '& .MuiInputBase-root': { padding: '8.5px 14px 40px' } }
    : null,
);
