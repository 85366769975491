import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { LinkDialogCreation } from 'components/link-upload/link-dialog-creation/link-dialog-creation';

import { UtilityAssessmentAttachment } from 'models/utility-assessment.model';

import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { ReactComponent as UploadFileIcon } from 'assets/upload-file-icon.svg';

import { StyledChip, StyledFileUploadWrapper } from './styles';
import { InputLinkUploadProps } from './types';

const isUtilityAssessmentAttachment = (
  item: unknown,
): item is UtilityAssessmentAttachment => {
  if (
    typeof item === 'object' &&
    item !== null &&
    'name' in item &&
    'url' in item &&
    'id' in item
  ) {
    const attachment = item as Partial<UtilityAssessmentAttachment>;

    return (
      typeof attachment.name === 'string' &&
      typeof attachment.url === 'string' &&
      typeof attachment.id === 'string'
    );
  }

  return false;
};

export const InputLinkUpload = ({
  control,
  fieldName,
  onUpdateTemplate,
}: InputLinkUploadProps) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  const [isOpenLinkDialog, setIsOpenLinkDialog] = useState(false);

  const attachments = Array.isArray(fields)
    ? fields.filter(isUtilityAssessmentAttachment)
    : [];

  const handleCloseDialog = () => setIsOpenLinkDialog(false);

  const handleDeleteAttachment = (index: number) => {
    remove(index);
    onUpdateTemplate?.();
  };

  const handleUploadAttachment = (data: UtilityAssessmentAttachment) => {
    append(data);
    onUpdateTemplate?.();
    handleCloseDialog();
  };

  return (
    <>
      <StyledFileUploadWrapper>
        {attachments.map(({ id, url }, index) => (
          <StyledChip
            key={id}
            variant="outlined"
            deleteIcon={<CloseIcon />}
            onDelete={() => handleDeleteAttachment(index)}
            onClick={() => window.open(url, '_blank')}
            label={`${t('link_dialog_creation.file', { index: index + 1 })}`}
          />
        ))}

        <Button
          component="label"
          startIcon={<UploadFileIcon />}
          onClick={() => setIsOpenLinkDialog(true)}
        >
          {t('attach_document')}
        </Button>
      </StyledFileUploadWrapper>

      <LinkDialogCreation
        open={isOpenLinkDialog}
        onClose={handleCloseDialog}
        onSubmit={handleUploadAttachment}
      />
    </>
  );
};
