import { FormProvider, useForm } from 'react-hook-form';

import { DUMMY_DATA_FROM_BE } from './constants';
import { ProjectFactsheetForm } from './project-factsheet-form/project-factsheet-form';

export const ProjectFactsheetPanel = () => {
  const methods = useForm({ defaultValues: DUMMY_DATA_FROM_BE });
  const { getValues } = methods;

  const handleUpdateFormOnBlur = async (fieldName: string) => {
    console.log(fieldName, getValues());

    // const value = watch(fieldName);
    // const data = {
    //   path: `${formName}.${fieldName}`,
    //   value,
    // };
    // await updateUtilityAssessmentForm({
    //   organizationId,
    //   data,
    // });
  };

  return (
    <FormProvider {...methods}>
      <ProjectFactsheetForm
        data={DUMMY_DATA_FROM_BE}
        onUpdateForm={handleUpdateFormOnBlur}
      />
    </FormProvider>
  );
};
