import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Grid, MenuItem, Select, Typography } from '@mui/material';

import { SCORE_BACKGROUND_COLOR } from 'pages/shortlist-criteria-page/constants';
import { CriteriaTitle } from 'pages/shortlist-criteria-page/shortlist-criteria-form/criteria-title/criteria-title';
import { StyledCriteriaWrapper } from 'pages/shortlist-criteria-page/shortlist-criteria-form/styles';

import { DEFAULT_SCORE_VALUE } from './constants';
import { StyledScoreWrapper } from './styles';
import { ProjectEvaluationFormProps } from './types';

export const ProjectEvaluationForm: FC<ProjectEvaluationFormProps> = ({
  data,
  onUpdateFormOnBlur,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  const filteredData = Object.entries(data).filter(
    ([fieldKey, { isApplied }]) =>
      !['_id', '__v', 'organizationId', 'projectId', 'projectScore'].includes(
        fieldKey,
      ) && isApplied,
  );

  return !filteredData.length ? (
    <Typography sx={{ py: 5 }}>
      {t(
        'investment_projects_page.investment_project.evaluation.no_shortlist_criteria_were_applied',
      )}
    </Typography>
  ) : (
    filteredData.map(([dataKey, dataValue], dataKeyIndex) => (
      <Box key={dataKey} pb={5} pt={dataKeyIndex === 0 ? 5 : 0}>
        <StyledCriteriaWrapper
          isCriteriaApplied={dataValue.isApplied?.toString()}
        >
          <Grid container gap={3}>
            <Grid item xs={10}>
              <CriteriaTitle
                titleKey={dataKey}
                isCriteriaApplied={dataValue.isApplied}
              />
            </Grid>

            <Grid item xs>
              <Typography
                sx={{ fontSize: 14, fontWeight: 700, textAlign: 'right' }}
              >
                {t(
                  'investment_projects_page.investment_project.evaluation.weights',
                  {
                    weights: `: ${dataValue.weights || DEFAULT_SCORE_VALUE}`,
                  },
                )}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <Controller
                name={`${dataKey}.value`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    fullWidth
                    value={field.value || DEFAULT_SCORE_VALUE}
                    onBlur={() => onUpdateFormOnBlur(field.name)}
                  >
                    <MenuItem value={DEFAULT_SCORE_VALUE}>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <StyledScoreWrapper bgcolor="grey.300">
                          {DEFAULT_SCORE_VALUE}
                        </StyledScoreWrapper>

                        <Typography>
                          {t('dropdown_options.not_assessed')}
                        </Typography>
                      </Box>
                    </MenuItem>

                    {dataValue.options.map(({ key, score }) => (
                      <MenuItem key={key} value={score}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <StyledScoreWrapper
                            bgcolor={SCORE_BACKGROUND_COLOR[score]}
                          >
                            {score}
                          </StyledScoreWrapper>

                          <Typography>
                            {t(`dropdown_options.${key}`)}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Grid>
          </Grid>
        </StyledCriteriaWrapper>
      </Box>
    ))
  );
};
