import { Service } from 'models/service.model';
import { Theme } from 'models/theme.model';

export const isOtherServiceChosen = (
  services: Service[],
  serviceId: string,
) => {
  return (
    services.find((s) => s._id === serviceId)?.name === 'other_waters_service'
  );
};
export const isOtherTargetedBenefitsChosen = (targetedBenefits: string[]) =>
  targetedBenefits.includes('other');
export const isOtherThemeChosen = (
  themes: Theme[],
  selectedThemes: string[],
) => {
  const otherThemeId = themes.find((t) => t.name === 'other_theme')?._id;

  return otherThemeId && selectedThemes.includes(otherThemeId);
};
export const isOtherOptionThanNoChosen = (value: string) =>
  value && value !== 'no';

export const isOtherOptionChosen = (value: string | null) =>
  value && value === 'other';

export const ownFundingIsPresented = (ownFunding: string) =>
  ownFunding !== 'no_own_funding';
