import { Typography, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledSectionTitle = styled(Typography)({
  fontWeight: 700,
  backgroundColor: theme.palette.grey[100],
  padding: 8,
  borderRadius: 4,
  marginBottom: 24,
});
