import { Box, styled } from '@mui/material';

import { theme } from 'configs/theme';

export const StyledScoreWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
  fontWeight: 700,
  borderRadius: 4,
  minWidth: 32,
  height: 32,
  marginRight: 16,
});
