import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { StyledDialog, StyledIconButton } from './styles';
import { ConfirmationDialogProps } from './types';

interface TranslationArgs {
  [key: string]: string | number;
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  open,
  maxWidth = 'xs',
  fullWidth = true,
  onClose,
  onSubmit,
  isDisabled = false,
  args,
}) => {
  const { t } = useTranslation();

  return (
    <StyledDialog
      onClose={onClose}
      open={open}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <DialogTitle>
        <StyledIconButton onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </DialogTitle>

      <DialogContent>
        {args ? t(title, args as TranslationArgs) : t(title)}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="error" onClick={onClose}>
          {t('cancel')}
        </Button>

        <Button
          disabled={isDisabled}
          variant="contained"
          color="error"
          type="submit"
          onClick={onSubmit}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
