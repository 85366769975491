import { Box, Chip, styled } from '@mui/material';

export const StyledChip = styled(Chip)({
  borderRadius: 4,
  borderColor: 'rgba(0, 0, 0, 0.23)',
  height: 40,
});

export const StyledFileUploadWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: 12,
});
