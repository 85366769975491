export const COLUMNS = [
  'brand_of_pumps',
  'productivity',
  'pressure',
  'water_inlet_pressure_is_min',
  'water_inlet_pressure_is_max',
  'output_pressure_day',
  'output_pressure_night',
  'water_pumping',
  'electricity_consumed_for_the_year',
  'number_of_subscribers',
  'number_of_consumers_of_the_population',
  'tm',
];
