import { useUpdateUtilityAssessmentFormByOrganizationId } from 'hooks/mutations/utility-assessment/utility-assessment';
import { FC, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  TechnicalAndEfficiencyAssessmentRoutes,
  UtilityAssessmentRoutes,
} from 'routes/constants';

import { queryKeys } from 'react-query/constants';

import { UtilityAssessmentContext } from 'pages/utility-assessment-page/context';
import { AssessmentTableSection } from 'pages/utility-assessment-page/technical-and-efficiency-assessment-panel/assessment-table-section/assessment-table-section';
import { UtilityAssessmentForms } from 'pages/utility-assessment-page/types';

import { AppContext } from 'context';

import { formatUtilityAssessmentValue } from 'utils';

import { COLUMNS } from './constants';
import { FormValues } from './types';

export const OperationOfWastewaterPumpingStationsTab: FC = () => {
  const { organizationId } = useParams();
  const { watch } = useFormContext();
  const {
    technicalAndEfficiencyAssessmentTab: {
      operationOfWastewaterPumpingStations,
      isFetchingTechnicalAndEfficiencyAssessmentData: isFetching,
    },
  } = useContext(UtilityAssessmentContext);
  const {
    commentsUniquePathId: { setCommentsUniquePathId },
  } = useContext(AppContext);

  const { mutateAsync: updateUtilityAssessmentForm } =
    useUpdateUtilityAssessmentFormByOrganizationId(
      queryKeys.getTechnicalAndEfficiencyAssessmentFormByOrganizationId,
      UtilityAssessmentForms.TECHNICAL_AND_EFFICIENCY_ASSESSMENT,
    );

  const handleUpdateFormOnBlur = async (fieldPath: string) => {
    const value = watch(fieldPath as keyof FormValues);

    if (value) {
      await updateUtilityAssessmentForm({
        organizationId,
        data: {
          path: `${UtilityAssessmentForms.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}.${fieldPath}`,
          value: formatUtilityAssessmentValue(fieldPath, value),
        },
      });
    }
  };

  useEffect(() => {
    if (!isFetching) {
      const uniqueCommentsPath = `${organizationId}${UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}${TechnicalAndEfficiencyAssessmentRoutes.OPERATION_OF_WASTEWATER_PUMPING_STATIONS}`;

      setCommentsUniquePathId(uniqueCommentsPath);
    }
  }, [isFetching, organizationId, setCommentsUniquePathId]);

  return (
    <AssessmentTableSection
      data={operationOfWastewaterPumpingStations}
      titleKey="operation_of_wastewater_pumping_stations"
      pathPrefix="operationOfWastewaterPumpingStations"
      columns={COLUMNS}
      onUpdate={handleUpdateFormOnBlur}
    />
  );
};
