import { Auth0ContextInterface } from '@auth0/auth0-react';

import {
  ShortlistCriteriaData,
  ShortlistCriteriaForProjectData,
} from 'models/shortlist-criteria.model';
import { UserModel } from 'models/user.model';

import { axiosGet, axiosUpdate } from './external-api.service';

export const shortlistCriteriaService = {
  getShortListCriteriaFormByOrganizationId: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
  ) => {
    if (!organizationId) return;

    return axiosGet<Record<string, ShortlistCriteriaData>>(
      auth,
      `/v2/shortlist-criteria/${organizationId}`,
    );
  },

  updateShortListCriteriaFormByOrganizationId: (
    auth: Auth0ContextInterface<UserModel>,
    organizationId: string | undefined,
    data: { path: string; value: number | boolean },
  ) => {
    if (!organizationId) return;

    return axiosUpdate(auth, `/v2/shortlist-criteria/${organizationId}`, data);
  },

  getShortListCriteriaForProjectByProjectId: (
    auth: Auth0ContextInterface<UserModel>,
    projectId: string | undefined,
  ) => {
    if (!projectId) return;

    return axiosGet<Record<string, ShortlistCriteriaForProjectData>>(
      auth,
      `/v2/shortlist-criteria-for-project/${projectId}`,
    );
  },

  updateShortListCriteriaForProjectByProjectId: (
    auth: Auth0ContextInterface<UserModel>,
    projectId: string | undefined,
    data: { path: string; value: number | boolean },
  ) => {
    if (!projectId) return;

    return axiosUpdate<
      Record<string, ShortlistCriteriaForProjectData>,
      { path: string; value: number | boolean }
    >(auth, `/v2/shortlist-criteria-for-project/${projectId}`, data);
  },
};
