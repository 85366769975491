import { Auth0ContextInterface } from '@auth0/auth0-react';

import { Comment } from 'models/comment.model';
import { UserModel } from 'models/user.model';

import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosPut,
} from './external-api.service';

export const commentService = {
  getCommentsByUniqueId: (
    auth: Auth0ContextInterface<UserModel>,
    uniqueId: string | undefined,
  ) => {
    if (!uniqueId) return;

    return axiosGet<Comment[]>(auth, `/v1/comments/${uniqueId}`);
  },

  createCommentByUniqueId: (
    auth: Auth0ContextInterface<UserModel>,
    uniqueId: string | undefined,
    data: Comment,
  ) => {
    if (!uniqueId) return;

    return axiosPost<Comment[], Comment>(
      auth,
      `/v1/comments/${uniqueId}`,
      data,
    );
  },

  updateCommentById: (
    auth: Auth0ContextInterface<UserModel>,
    commentId: string | undefined,
    data: Comment,
  ) => {
    if (!commentId) return;

    return axiosPut<Comment, Comment>(auth, `/v1/comments/${commentId}`, data);
  },

  deleteCommentById: (
    auth: Auth0ContextInterface<UserModel>,
    commentId: string | undefined,
  ) => axiosDelete(auth, `/v1/comments/${commentId}`),
};
