export const devMode = process.env.NODE_ENV === 'development';

export const prodMode = process.env.NODE_ENV === 'production';

export enum StatusCodes {
  NOT_FOUND = 404,
  UNAUTHORIZED = 401,
  BAS_REQUEST = 400,
  ACCEPTED = 202,
  SUCCESS = 200,
}

export enum KeyboardKeys {
  ENTER = 'Enter',
}

export const headerHeight = 80;

export const surveyLayoutHeight = 305;

export const languageLocaleStorageKey = 'i18nextLng';

export const emptyString = '';

export const wordOfficeDocumentMimeType =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const imageMimeTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/webp',
];

export const documentMimeTypes = [
  'application/pdf',
  'application/msword',
  wordOfficeDocumentMimeType,
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
];

export const ALL_FILE_MIME_TYPES = [
  ...documentMimeTypes,
  ...imageMimeTypes,
  wordOfficeDocumentMimeType,
].join();

export const MIN_LENGTH_CHARACTERS = 3;

export const MAX_LENGTH_COMMENT_CHARACTERS = 500;

export const MAX_LENGTH_TEXT_CHARACTERS = 300;

export const MAX_LENGTH_MULTILINE_TEXT_CHARACTERS = 5000;

export const COMMON_FORM_CONTROL_LABEL_STYLES = {
  alignItems: 'flex-start',
  mx: 'unset',
  width: '100%',
};

export interface TranslationPair {
  en: string;
  ua: string;
}
