export interface MenuProps {
  options?: MenuActions[];
  anchorEl?: HTMLElement | null;
  open?: boolean;
  onClose?: () => void;
  onOptionClick?: (action: MenuActions) => void;
}

export enum MenuActions {
  EDIT = 'edit',
  EXPORT = 'export',
  DELETE = 'delete',
}
