import { forwardRef } from 'react';

import { SwitchProps } from '@mui/material';

import { StyledSwitch } from './styles';

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
  (props, ref) => <StyledSwitch {...props} ref={ref} />,
);

Switch.displayName = 'Switch';
