import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledActionCell = styled(Box)(() => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
}));

export const StyledChip = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: '4px 8px',
  borderRadius: '4px',
  background: '#4B5565',
  color: '#FFF',
  fontSize: 14,
  height: 27,
}));

export const StyledScore = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  padding: 8,
  borderRadius: 4,
  fontSize: 22,
  color: theme.palette.common.white,
}));

export const StyledTBD = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  padding: '4px 8px',
  borderRadius: '4px',
  border: '1px solid #9AA4B2',
  background: 'transparent',
  color: '#9AA4B2',
  fontSize: 14,
  height: 27,
}));
