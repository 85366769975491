import { ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';

import { Box } from '@mui/material';

import { StyledChip } from './styles';
import { TBDCellRenderer } from './tbd-cell-renderer';

export const ChipCellRenderer: FC<ICellRendererParams> = (props) => {
  const { valueFormatted } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      {valueFormatted ? (
        <StyledChip>{valueFormatted}</StyledChip>
      ) : (
        <TBDCellRenderer {...props} />
      )}
    </Box>
  );
};
