import { TableCell, Typography, styled } from '@mui/material';

export const StyledSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  backgroundColor: theme.palette.grey[100],
  padding: 8,
  borderRadius: 4,
}));

export const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  minWidth: 100,
  padding: '0 16px',
  position: 'sticky',
  left: 0,
  zIndex: 3,
  backgroundColor: theme.palette.background.default,
  border: 'none',
}));

export const StyledTableHeaderColumnCell = styled(TableCell)({
  minWidth: 200,
  padding: '0 16px',
  backgroundColor: 'unset',
  border: 'none',
});

export const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  minWidth: 100,
  position: 'sticky',
  left: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.default,
  border: 'none',
  padding: 16,
}));
