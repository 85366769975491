import { Dispatch, ReactNode, SetStateAction } from 'react';

import { AlertColor } from '@mui/material';

export interface ContextType {
  userResponseId: ContextUserResponseId;
  commentsUniquePathId: ContextCommentsUniquePathId;
  notification: Notification;
}

interface Notification {
  type: AlertColor;
  message: Error | string | ReactNode;
  subtitle?: string;
  hideDuration?: number | 'disabled';
  isShowingNotification: boolean;
  showNotification: (showNotificationProps: NotificationState) => void;
  closeNotification: () => void;
}

export interface NotificationState {
  isShowingNotification: boolean;
  message: Error | string | ReactNode;
  subtitle?: string;
  hideDuration?: number | 'disabled';
  type: AlertColor;
}

export interface ContextUserResponseId {
  userResponseId: string | undefined;
  setUserResponseId: Dispatch<SetStateAction<string | undefined>>;
}

export interface ContextCommentsUniquePathId {
  commentsUniquePathId: string | undefined;
  setCommentsUniquePathId: Dispatch<SetStateAction<string | undefined>>;
}

export enum Severity {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error',
}
