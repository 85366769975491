import { yupResolver } from '@hookform/resolvers/yup';
import {
  useCreateProject,
  useGetInvestmentProjectMetadata,
} from 'hooks/queries/investment-project/investment-project';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import {
  ProjectCreatePayload,
  ProjectCreateValues,
} from 'models/investment-project.model';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

import { ProjectCreationForm } from './project-creation-form/project-creation-form';
import { StyledIconButton } from './styles';
import { ProjectCreationDialogProps } from './types';

const createProjectValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  service: Yup.string().nullable().defined(),
  category: Yup.string().nullable().defined(),
  targetedBenefits: Yup.array()
    .of(Yup.string().required('Each benefit is required'))
    .defined(),
  totalCost: Yup.number()
    .nullable()
    .positive('Total cost must be greater than 0')
    .defined(),
  feasibilityStudies: Yup.string().nullable().defined(),
  priorityFactsheet: Yup.string().nullable().defined(),
});

export const ProjectCreationDialog = ({
  open,
  onClose,
}: ProjectCreationDialogProps) => {
  const { currentLanguage } = useIsEnLanguage();
  const { organizationId } = useParams();
  const {
    data: investmentProjectMetadata,
    isLoading: investmentProjectMetadataIsLoading,
  } = useGetInvestmentProjectMetadata();

  const { mutateAsync: createProject, isLoading: createProjectIsLoading } =
    useCreateProject();

  const { t } = useTranslation();

  const defaultValues: ProjectCreateValues = {
    name: '',
    service: null,
    category: null,
    targetedBenefits: [] as string[],
    totalCost: null,
    feasibilityStudies: null,
    priorityFactsheet: null,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(createProjectValidationSchema),
  });
  const { handleSubmit, reset } = methods;

  const onSubmit = async (data: ProjectCreateValues) => {
    const payload: ProjectCreatePayload = {
      name: { [currentLanguage]: data.name },
      service: {
        value: data.service,
        details: data.serviceDetails
          ? { [currentLanguage]: data.serviceDetails }
          : null,
      },
      category: data.category,
      targetedBenefits: {
        value: data.targetedBenefits,
        details: data.targetedBenefitsDetails
          ? { [currentLanguage]: data.targetedBenefitsDetails }
          : null,
      },
      totalCost: data.totalCost,
      feasibilityStudies: {
        value: data.feasibilityStudies,
        details: data.feasibilityStudiesDetails
          ? { [currentLanguage]: data.feasibilityStudiesDetails }
          : null,
      },
      priorityFactsheet: {
        value: data.priorityFactsheet,
        details: data.priorityFactsheetDetails
          ? { [currentLanguage]: data.priorityFactsheetDetails }
          : null,
      },
    };

    try {
      if (organizationId) {
        await createProject({ ...payload, organizationId });
      }
      reset();
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      {investmentProjectMetadataIsLoading || !investmentProjectMetadata ? (
        <CircularProgress />
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle sx={{ pb: 5 }}>
              <StyledIconButton onClick={onClose}>
                <CloseIcon />
              </StyledIconButton>
            </DialogTitle>

            <DialogContent>
              <ProjectCreationForm
                investmentProjectMetadata={investmentProjectMetadata}
              />
            </DialogContent>

            <DialogActions sx={{ px: 3, pb: 3 }}>
              <Button
                disabled={createProjectIsLoading}
                variant="outlined"
                onClick={onClose}
              >
                {t('cancel')}
              </Button>

              <Button
                disabled={createProjectIsLoading}
                variant="contained"
                type="submit"
              >
                {t(
                  'investment_projects_page.project_creation_dialog.add_new_project_btn',
                )}
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      )}
    </Dialog>
  );
};
