import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import {
  StyledTitle as MainStyledTitle,
  StyledBlockWrapper,
} from 'components/processes/process-item/styles';

import InvestmentPlanIcon from 'assets/investment-plan-icon.svg';

import { theme } from 'configs/theme';

import { investmentPlanBlockItems } from './constants';
import { InvestmentPlanSectionItem } from './investment-plan-section-item/investment-plan-section-item';

export const InvestmentPlanSection = () => {
  const { t } = useTranslation();

  const mainInfoBlock = (
    <StyledBlockWrapper bgGradient="linear-gradient(130.44deg, #518BFF -3.51%, #00349E 126.91%)">
      <img
        src={InvestmentPlanIcon}
        alt={t('home_screen.investment_plan.title')}
        style={{ width: 80 }}
      />

      <MainStyledTitle>
        {t('home_screen.investment_plan.title')}
      </MainStyledTitle>
    </StyledBlockWrapper>
  );

  return (
    <Grid item xs={12}>
      <Grid
        container
        sx={{
          borderRadius: '8px',
          border: `1px solid ${theme.palette.grey[300]}`,
        }}
      >
        <Grid item xs={3}>
          {mainInfoBlock}
        </Grid>

        {investmentPlanBlockItems.map((item) => (
          <InvestmentPlanSectionItem key={item.path} item={item} />
        ))}
      </Grid>
    </Grid>
  );
};
