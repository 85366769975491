import { forwardRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, TextField, Typography } from '@mui/material';

import { COMMON_FORM_CONTROL_LABEL_STYLES } from 'constants/index';

import { TextInputProps } from './types';

export const TextInput = forwardRef<HTMLDivElement, TextInputProps>(
  ({ name, label, onBlur, multiline, rows, type = 'text' }, ref) => {
    const { t } = useTranslation();
    const { control } = useFormContext();

    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControlLabel
            sx={{
              ...COMMON_FORM_CONTROL_LABEL_STYLES,
              height: multiline ? '100%' : 'auto',
            }}
            control={
              <TextField
                {...field}
                error={!!error}
                helperText={error?.message}
                fullWidth
                multiline={multiline}
                rows={rows}
                onBlur={onBlur}
                InputProps={
                  multiline
                    ? {
                        style: { height: '100%', alignItems: 'flex-start' },
                      }
                    : undefined
                }
                value={field.value ?? undefined}
                size="small"
                type={type}
              />
            }
            label={
              label && (
                <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.5 }}>
                  {t(label)}
                </Typography>
              )
            }
            labelPlacement="top"
            ref={ref}
          />
        )}
      />
    );
  },
);

TextInput.displayName = 'TextInput';
