import { useUpdateUtilityAssessmentFormByOrganizationId } from 'hooks/mutations/utility-assessment/utility-assessment';
import { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  TechnicalAndEfficiencyAssessmentRoutes,
  UtilityAssessmentRoutes,
} from 'routes/constants';

import { Divider, Grid, TextField, Typography } from '@mui/material';

import { queryKeys } from 'react-query/constants';

import { UtilityAssessmentContext } from 'pages/utility-assessment-page/context';
import { UtilityAssessmentForms } from 'pages/utility-assessment-page/types';

import { InputLinkUpload } from 'components/link-upload/link-upload';
import { PageLoader } from 'components/page-loader/page-loader';

import { AppContext } from 'context';

import { formatUtilityAssessmentValue, getValue, toSnakeCase } from 'utils';

import { useIsEnLanguage } from 'hooks/hooks/useIsEnLanguage';

export const PreliminaryQuestionsTab = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const { currentLanguage } = useIsEnLanguage();
  const {
    technicalAndEfficiencyAssessmentTab: {
      preliminaryQuestions,
      isFetchingTechnicalAndEfficiencyAssessmentData: isFetching,
    },
  } = useContext(UtilityAssessmentContext);
  const { watch, reset, control } = useForm({
    defaultValues: preliminaryQuestions,
  });

  const {
    commentsUniquePathId: { setCommentsUniquePathId },
  } = useContext(AppContext);

  const { mutateAsync: updateUtilityAssessmentForm } =
    useUpdateUtilityAssessmentFormByOrganizationId(
      queryKeys.getTechnicalAndEfficiencyAssessmentFormByOrganizationId,
      UtilityAssessmentForms.TECHNICAL_AND_EFFICIENCY_ASSESSMENT,
    );

  const handleUpdateFormOnBlur = async (fieldPath: string) => {
    const value = watch(fieldPath);

    if (value) {
      await updateUtilityAssessmentForm({
        organizationId,
        data: {
          path: `${UtilityAssessmentForms.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}.preliminaryQuestions.${fieldPath}`,
          value: formatUtilityAssessmentValue(fieldPath, value),
        },
      });
    }
  };

  useEffect(() => {
    if (!isFetching) {
      const uniqueCommentsPath = `${organizationId}${UtilityAssessmentRoutes.TECHNICAL_AND_EFFICIENCY_ASSESSMENT}${TechnicalAndEfficiencyAssessmentRoutes.PRELIMINARY_QUESTIONS}`;

      setCommentsUniquePathId(uniqueCommentsPath);
    }
  }, [isFetching, organizationId, setCommentsUniquePathId]);

  useEffect(() => {
    if (!isFetching && preliminaryQuestions) {
      reset(preliminaryQuestions);
    }
  }, [preliminaryQuestions, isFetching, currentLanguage, reset]);

  if (isFetching) {
    return <PageLoader height="100%" />;
  }

  return (
    <Grid container spacing={3}>
      {preliminaryQuestions &&
        Object.keys(preliminaryQuestions)
          .filter((fieldKey) => !['_id', 'comments'].includes(fieldKey))
          .map((fieldKey, fieldIndex) => (
            <Grid item key={fieldKey} xs={12}>
              <Typography sx={{ fontWeight: 800, mb: 1 }}>
                {t(
                  `utility_assessment_page.technical_and_efficiency_assessment.preliminary_questions.${toSnakeCase(fieldKey)}`,
                )}
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name={`${fieldKey}.explanation.${currentLanguage}`}
                    control={control}
                    render={({ field, fieldState: { error, isDirty } }) => (
                      <TextField
                        {...field}
                        value={getValue(field.value, currentLanguage)}
                        error={!!error}
                        multiline
                        rows={7}
                        helperText={error?.message}
                        fullWidth
                        onBlur={() => {
                          if (isDirty) {
                            handleUpdateFormOnBlur(field.name);
                          }
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLinkUpload
                    control={control}
                    fieldName={`${fieldKey}.attachments`}
                    onUpdateTemplate={() =>
                      handleUpdateFormOnBlur(`${fieldKey}.attachments`)
                    }
                  />
                </Grid>
              </Grid>

              {fieldIndex < Object.keys(preliminaryQuestions).length - 1 && (
                <Divider sx={{ mt: 3 }} />
              )}
            </Grid>
          ))}
    </Grid>
  );
};
