import { ICellRendererParams } from 'ag-grid-community';
import { usePatchProject } from 'hooks/queries/investment-project/investment-project';
import { FC, useState } from 'react';

import { Box, Checkbox } from '@mui/material';

export const ShortlistCellRenderer: FC<ICellRendererParams> = (props) => {
  const {
    value,
    data: { _id },
  } = props;

  const { mutateAsync: patchProject } = usePatchProject(_id);

  const [isChecked, setIsChecked] = useState(value);

  const handleChange = async (checked: boolean) => {
    setIsChecked(checked);

    await patchProject({
      projectPatchPayload: { shortlist: checked },
      fieldName: 'shortlist',
      translatable: false,
    });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      <Checkbox
        checked={isChecked}
        onChange={(_, checked) => handleChange(checked)}
      />
    </Box>
  );
};
