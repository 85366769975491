import { ICellRendererParams } from 'ag-grid-community';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import IconButton from '@mui/material/IconButton';

import { StyledActionCell } from './styles';

const ActionCellRenderer: FC<
  ICellRendererParams & { onDelete: (id: string) => void }
> = ({ data, onDelete }) => {
  const navigate = useNavigate();
  const handleEdit = () => {
    navigate(data._id);
  };

  const handleDelete = () => {
    onDelete(data._id);
  };

  return (
    <StyledActionCell>
      <IconButton onClick={handleEdit} color="default" aria-label="edit">
        <EditIcon />
      </IconButton>
      <IconButton onClick={handleDelete} color="default" aria-label="delete">
        <DeleteIcon />
      </IconButton>
    </StyledActionCell>
  );
};

export default ActionCellRenderer;
