import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation, useQuery } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { queryClient } from 'react-query/queryClient';

import {
  PatchProject,
  ProjectCreatePayload,
} from 'models/investment-project.model';
import { UserModel } from 'models/user.model';

import { investmentProjectService } from 'services/investment-project.service';

import { AppContext } from 'context';

import {
  UseGetInvestmentProject,
  UseGetInvestmentProjectMetadata,
  UseGetInvestmentProjects,
} from './types';

export const useGetInvestmentProjectMetadata =
  (): UseGetInvestmentProjectMetadata => {
    const auth = useAuth0<UserModel>();
    const { t } = useTranslation();
    const {
      notification: { showNotification },
    } = useContext(AppContext);

    const { data, isLoading, isError } = useQuery(
      queryKeys.getInvestmentProjectMetadata,
      () => investmentProjectService.getInvestmentProjectMetadata(auth),
      {
        staleTime: Infinity,
        onError: (error: AxiosError) => {
          const errorMessage =
            (error.response?.data as { message: string })?.message ||
            t('errors.unknown_error_occurred');

          showNotification({
            isShowingNotification: true,
            type: Severity.Error,
            message: errorMessage,
          });
        },
      },
    );

    return {
      data: data,
      isLoading,
      isError,
    };
  };

export const useGetInvestmentProjects = (
  organisationId?: string,
): UseGetInvestmentProjects => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError } = useQuery(
    queryKeys.getInvestmentProjects,
    () => investmentProjectService.getProjects(auth, organisationId!),
    {
      enabled: !!organisationId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data: data,
    isLoading,
    isError,
  };
};

export const useGetInvestmentProject = (
  projectId?: string,
): UseGetInvestmentProject => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { data, isLoading, isError } = useQuery(
    [queryKeys.getInvestmentProject, projectId],
    () => investmentProjectService.getProjectById(auth, projectId!),
    {
      enabled: !!projectId,
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    data: data,
    isLoading,
    isError,
  };
};

export const useCreateProject = () => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading, isSuccess, isError } = useMutation({
    mutationKey: [queryKeys.createInvestmentProject],
    mutationFn: (
      projectData: ProjectCreatePayload & { organizationId: string },
    ) => investmentProjectService.createProject(auth, projectData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getInvestmentProjects],
      });
    },
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as { message: string })?.message ||
        t('errors.unknown_error_occurred');

      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: errorMessage,
      });
    },
  });

  return { mutateAsync, isLoading, isSuccess, isError };
};

export const usePatchProject = (projectId: string) => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation({
    mutationKey: [queryKeys.getInvestmentProject, projectId],
    mutationFn: ({
      projectPatchPayload,
      fieldName,
      translatable,
    }: {
      projectPatchPayload: PatchProject;
      fieldName: string;
      translatable: boolean;
    }) =>
      investmentProjectService.patchProject(auth, projectId, {
        projectField: projectPatchPayload,
        fieldName,
        translatable,
      }),
    onSuccess: (data) => {
      queryClient.setQueryData(
        [queryKeys.getInvestmentProject, projectId],
        data,
      );
    },
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as { message: string })?.message ||
        t('errors.unknown_error_occurred');

      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: errorMessage,
      });
    },
  });

  return { mutateAsync, isLoading, isSuccess, isError };
};

export const useDeleteProject = () => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);
  const { mutateAsync, isLoading, isSuccess, isError } = useMutation({
    mutationKey: [queryKeys.deleteInvestmentProject],
    mutationFn: (projectId: string) =>
      investmentProjectService.deleteProject(auth, projectId),
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.getInvestmentProjects]);
    },
    onError: (error: AxiosError) => {
      const errorMessage =
        (error.response?.data as { message: string })?.message ||
        t('errors.unknown_error_occurred');

      showNotification({
        isShowingNotification: true,
        type: Severity.Error,
        message: errorMessage,
      });
    },
  });

  return { mutateAsync, isLoading, isSuccess, isError };
};
