import { TFunction } from 'i18next/typescript/t';
import React from 'react';

import { Box, Typography } from '@mui/material';

export const renderTooltipTitle = (t: TFunction<'translation', undefined>) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, gap: 2 }}>
    <Box>
      <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
        {t('categories.business_performance_category_title')}
      </Typography>

      <Typography sx={{ fontSize: 14 }}>
        {t('categories.business_performance_category_description')}
      </Typography>
    </Box>
    <Box>
      <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
        {t('categories.infrastructure_project_category_title')}
      </Typography>

      <Typography sx={{ fontSize: 14 }}>
        {t('categories.infrastructure_project_category_description')}
      </Typography>
    </Box>
    <Box>
      <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
        {t('categories.operational_project_category_title')}
      </Typography>

      <Typography sx={{ fontSize: 14 }}>
        {t('categories.operational_project_category_description')}
      </Typography>
    </Box>
  </Box>
);
