import { AxiosError } from 'axios';
import { Severity } from 'context/types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '@auth0/auth0-react';

import { useMutation } from 'react-query';
import { queryKeys } from 'react-query/constants';
import { queryClient } from 'react-query/queryClient';

import { UserModel } from 'models/user.model';

import { shortlistCriteriaService } from 'services/shortlist-criteria.service';

import { AppContext } from 'context';

import { UseUpdateShortListCriteriaForm } from './types';

export const useUpdateShortListCriteriaFormByOrganizationId = () => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ data, id }: UseUpdateShortListCriteriaForm) =>
      await shortlistCriteriaService.updateShortListCriteriaFormByOrganizationId(
        auth,
        id,
        data,
      ),
    {
      mutationKey: queryKeys.updateShortListCriteriaFormByOrganizationId,
      onSuccess: (data) => {
        if (data) {
          queryClient.setQueryData(
            [queryKeys.getShortListCriteriaFormByOrganizationId],
            data,
          );
        }
      },
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};

export const useUpdateShortListCriteriaForProjectByProjectId = (
  projectId?: string,
) => {
  const auth = useAuth0<UserModel>();
  const { t } = useTranslation();
  const {
    notification: { showNotification },
  } = useContext(AppContext);

  const { mutateAsync, isLoading } = useMutation(
    async ({ data, id }: UseUpdateShortListCriteriaForm) =>
      await shortlistCriteriaService.updateShortListCriteriaForProjectByProjectId(
        auth,
        id,
        data,
      ),
    {
      mutationKey: queryKeys.updateShortListCriteriaForProjectByProjectId,
      onSuccess: (data) =>
        queryClient.setQueryData(
          [queryKeys.getInvestmentProject, projectId],
          // TODO: fix type
          (oldData: any) => ({
            ...oldData,
            evaluation: { ...oldData.evaluation, score: data?.score },
          }),
        ),
      onError: (error: AxiosError) => {
        const errorMessage =
          (error.response?.data as { message: string })?.message ||
          t('errors.unknown_error_occurred');

        showNotification({
          isShowingNotification: true,
          type: Severity.Error,
          message: errorMessage,
        });
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
  };
};
