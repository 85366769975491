import { InvestmentPlanRoutes } from 'routes/constants';

import { InvestmentPlanBlockItem } from './types';

export const investmentPlanBlockItems: InvestmentPlanBlockItem[] = [
  {
    path: InvestmentPlanRoutes.INVESTMENT_PROJECTS,
    title: 'investment_projects_page',
  },
  {
    path: InvestmentPlanRoutes.SHORTLIST_CRITERIA,
    title: 'shortlist_criteria_page',
  },
  {
    path: InvestmentPlanRoutes.UTILITY_ASSESSMENT,
    title: 'utility_assessment_page',
    isLastBlock: true,
  },
];
