import { useLocation, useSearchParams } from 'react-router-dom';

export const useCurrentTab = (
  validTabs: string[],
  searchParam?: string,
): string => {
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();

  if (searchParam) {
    const tab = searchParams.get(searchParam);

    return tab || '';
  } else {
    const pathnamePieces = pathname.split('/');

    const lastSegment = pathnamePieces[pathnamePieces.length - 1];

    if (validTabs.includes(lastSegment)) {
      return lastSegment;
    } else {
      // Logic to handle if the last segment is an ID or invalid
      return ''; // or some default tab name
    }
  }
};
