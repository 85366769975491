import { Auth0ContextInterface } from '@auth0/auth0-react';

import { Attachment } from 'models/attachments.model';
import { UserModel } from 'models/user.model';

import { axiosDelete, axiosGet, axiosPost } from './external-api.service';

export const attachmentService = {
  uploadAttachments: (
    auth: Auth0ContextInterface<UserModel>,
    data: FormData,
  ) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return axiosPost<Attachment[], FormData>(
      auth,
      '/v1/attachments',
      data,
      headers,
    );
  },

  getAttachmentUrl: (auth: Auth0ContextInterface<UserModel>, id: string) =>
    axiosGet<string>(auth, `/v1/attachments/${id}`),

  deleteAttachmentById: (auth: Auth0ContextInterface<UserModel>, id: string) =>
    axiosDelete(auth, `/v1/attachments/${id}`),
};
