import { ProjectFactsheetFormData } from 'models/project-factsheet-form.model';

export const TRANSLATION_KEY =
  'investment_projects_page.investment_project.project_factsheet';

export const DUMMY_DATA_FROM_BE: ProjectFactsheetFormData = {
  descriptionOfTheProject: {
    questions: {
      typeOfProject: {
        titleKey: 'questions.type_of_project.title',
        value: '',
        options: [
          'Business performance project',
          'Infrastructure Project',
          'Operational project',
        ],
      },

      typeOfService: {
        titleKey: 'questions.type_of_service.title',
        value: '',
        options: ['Drinking Water', 'Waste Water'],
      },

      location: {
        titleKey: 'questions.location.title',
        descriptionKey: 'questions.location.description',
        value: {
          en: '',
          ua: '',
        },
      },

      technicalSpecs: {
        titleKey: 'questions.technical_specs.title',
        descriptionKey: 'questions.technical_specs.description',
        value: {
          en: '',
          ua: '',
        },
      },

      projectCostEstimate: {
        titleKey: 'questions.project_cost_estimate.title',
        descriptionKey: 'questions.project_cost_estimate.description',
        value: {
          en: '',
          ua: '',
        },
      },

      proposedProcurementStrategy: {
        titleKey: 'questions.proposed_procurement_strategy.title',
        descriptionKey: 'questions.proposed_procurement_strategy.description',
        value: {
          en: '',
          ua: '',
        },
      },

      background: {
        titleKey: 'questions.background.title',
        descriptionKey: 'questions.background.description',
        value: {
          en: '',
          ua: '',
        },
      },

      requiredFunding: {
        titleKey: 'questions.required_funding.title',
        descriptionKey: 'questions.required_funding.description',
        value: '',
        options: ['Debt: %', 'Equity/own funds: %', 'Grant/subsidy: %'],
      },
    },
  },

  quickScanResults: {
    steps: {
      initialTimingAndImpactOnStrategicGoals: {
        title: 'initial_timing_and_impact_on_strategic_goals',

        questions: {
          preferredTiming: {
            titleKey:
              'steps.initial_timing_and_impact_on_strategic_goals.questions.preferred_timing.title',
            value: '',
            options: [
              '0-5 yrs',
              '5-10 yrs',
              '10-20 yrs',
              '20-30 yrs',
              '30-40 yrs',
            ],
          },

          // TODO: creates question key when it's not necessary
          alignmentWithStrategicGoals: {
            titleKey:
              'steps.initial_timing_and_impact_on_strategic_goals.questions.alignment_with_strategic_goals.title',
            descriptionKey:
              'steps.initial_timing_and_impact_on_strategic_goals.questions.alignment_with_strategic_goals.description',
            value: {
              en: '',
              ua: '',
            },
          },
        },
      },

      feasibilityFundingFinancingAndUrgency: {
        title: 'feasibility_funding_financing_and_urgency',

        subSteps: {
          technicalFeasibility: {
            title:
              'feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.title',

            questions: {
              knownTechnology: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.known_technology.title',
                options: ['Proven in Ukraine', 'Proven in Europe', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },

              originOfTheTechnology: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.origin_of_the_technology.title',
                options: ['Local', 'International', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },

              buildingResourcesAvailability: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.building_resources_availability.title',
                options: ['Locally available', 'International import', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },

              implementationEasiness: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.implementation_easiness.title',
                options: ['High easiness', 'Medium', 'Low easiness'],
                descriptionKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.implementation_easiness.description',
                value: '',
              },

              feasibilityStudyDone: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.feasibility_study_done.title',
                options: ['Feasibility Study', 'TEO', 'Preliminary FS'],
                value: '',
              },

              designReadiness: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.design_readiness.title',
                options: [
                  'Design documentation (Stage П, Р)',
                  'Expertise examination conclusion (positive)',
                  'Other',
                ],
                value: '',
                explanation: { en: '', ua: '' },
              },

              esiaDone: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.esia_done.title',
                options: [
                  'ESIA done',
                  'EIA done',
                  'Preliminary assessment of impact on environment done',
                ],
                value: '',
              },

              idpInflux: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.idp_influx.title',
                options: ['Yes', 'No', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },

              warDamage: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.war_damage.title',
                options: ['Yes', 'No', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },

              responseOfTheProposedMeasuresToMeetCurrentAndFutureDemand: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.response_of_the_proposed_measures_to_meet_current_and_future_demand.title',
                value: {
                  en: '',
                  ua: '',
                },
              },

              technicalSolutionsInContextOfCurrentDemandAndWaterUtilityCapabilities:
                {
                  titleKey:
                    'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.technical_solutions_in_context_of_current_demand_and_water_utility_capabilities.title',
                  value: {
                    en: '',
                    ua: '',
                  },
                },

              projectIsRespondToTheListOfEligibilities: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.project_is_respond_to_the_list_of_eligibilities.title',
                value: {
                  en: '',
                  ua: '',
                },
              },

              projectRespondsToPrioritiesRdna: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.project_responds_to_priorities_rdna.title',
                value: {
                  en: '',
                  ua: '',
                },
              },

              additionalConnections: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.additional_connections.title',
                options: ['< 25,000', '25 - 100,000', '100,000 +'],
                value: '',
              },

              additionalConnectionsLang: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.technical_feasibility.questions.additional_connections.title',
                value: {
                  en: '',
                  ua: '',
                },
              },
            },
          },

          financialFeasibility: {
            title:
              'feasibility_funding_financing_and_urgency.sub_steps.financial_feasibility.title',

            questions: {
              additionalExpectedRevenues: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.financial_feasibility.questions.additional_expected_revenues.title',
                value: {
                  en: '',
                  ua: '',
                },
              },

              additionalCostsSaved: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.financial_feasibility.questions.additional_costs_saved.title',
                value: {
                  en: '',
                  ua: '',
                },
              },

              npv: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.financial_feasibility.questions.npv.title',
                options: ['< 0', '> 0', 'N/A'],
                value: '',
              },

              dscr: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.financial_feasibility.questions.dscr.title',
                options: ['> 1.5', '1.35 - 1.49', '1 - 1.34', '< 1'],
                value: '',
              },

              potentialToAttractInvestorsFinancing: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.financial_feasibility.questions.potential_to_attract_investors_financing.title',
                options: ['High', 'Medium', 'Low'],
                value: '',
              },

              potentialToDevelopTradeRelationsOrInvestmentOpportunities: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.financial_feasibility.questions.potential_to_develop_trade_relations_or_investment_opportunities.title',
                options: ['High', 'Medium', 'Low'],
                value: '',
              },
            },
          },

          socialAndEnvironmentalImpact: {
            title:
              'feasibility_funding_financing_and_urgency.sub_steps.social_and_environmental_impact.title',

            questions: {
              includesQuantificationOfPositiveImpactOnEnvironmentAndResourceEfficiency:
                {
                  titleKey:
                    'steps.feasibility_funding_financing_and_urgency.sub_steps.social_and_environmental_impact.questions.includes_quantification_of_positive_impact_on_environment_and_resource_efficiency.title',
                  options: ['Yes', 'No', 'Other'],
                  value: '',
                  explanation: { en: '', ua: '' },
                },

              includesAssessmentOfPositiveImpactOnEnvironmentAndResourceEfficiency:
                {
                  titleKey:
                    'steps.feasibility_funding_financing_and_urgency.sub_steps.social_and_environmental_impact.questions.includes_assessment_of_positive_impact_on_environment_and_resource_efficiency.title',
                  options: ['Yes', 'No', 'Other'],
                  value: '',
                  explanation: { en: '', ua: '' },
                },

              includesInformationOnThePositiveImpactButNoDocumentation: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.social_and_environmental_impact.questions.includes_information_on_the_positive_impact_but_no_documentation.title',
                options: ['Yes', 'No', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },

              co2EmissionReduction: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.social_and_environmental_impact.questions.co2_emission_reduction.title',
                options: ['Yes', 'No', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },

              potentialContributionToSdgs: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.social_and_environmental_impact.questions.potential_contribution_to_sdgs.title',
                options: ['High', 'Medium', 'Low'],
                value: '',
              },

              potentialDriveSustainabilityAspects: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.social_and_environmental_impact.questions.potential_drive_sustainability_aspects.title',
                options: ['High', 'Medium', 'Low'],
                value: '',
              },

              compliantWithSwedfundsPolicyForSustainableDevelopment: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.social_and_environmental_impact.questions.compliant_with_swedfunds_policy_for_sustainable_development.title',
                options: ['High', 'Medium', 'Low'],
                value: '',
              },
            },
          },

          institutionalFit: {
            title:
              'feasibility_funding_financing_and_urgency.sub_steps.institutional_fit.title',

            questions: {
              priorityLevelByVodokanal: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.institutional_fit.questions.priority_level_by_vodokanal.title',
                options: ['High', 'Medium', 'Low'],
                value: '',
              },

              consistentWithTheStateStrategy: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.institutional_fit.questions.consistent_with_the_state_strategy.title',
                options: ['Yes', 'No', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },

              demonstratedEffect: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.institutional_fit.questions.demonstrated_effect.title',
                descriptionKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.institutional_fit.questions.demonstrated_effect.description',
                options: ['Yes', 'No', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },

              presenceInApprovedProgram: {
                titleKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.institutional_fit.questions.presence_in_approved_program.title',
                descriptionKey:
                  'steps.feasibility_funding_financing_and_urgency.sub_steps.institutional_fit.questions.presence_in_approved_program.description',
                options: ['Yes', 'No', 'Other'],
                value: '',
                explanation: { en: '', ua: '' },
              },
            },
          },
        },
      },
    },
  },
};
