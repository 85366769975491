import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@mui/material';

import { toSnakeCase } from 'utils';

import { CriteriaTitleProps } from './types';

export const CriteriaTitle = memo(
  ({ titleKey, isCriteriaApplied }: CriteriaTitleProps) => {
    const { t } = useTranslation();

    return (
      <>
        <Typography
          sx={{
            fontWeight: 800,
            fontSize: 18,
            color: !isCriteriaApplied ? 'graymodern.400' : 'unset',
          }}
        >
          {t(`shortlist_criteria_page.${toSnakeCase(titleKey)}.title`, {
            weights: '',
          })}
        </Typography>

        <Typography
          sx={{
            fontSize: 14,
            color: !isCriteriaApplied ? 'graymodern.400' : 'unset',
          }}
        >
          {t(`shortlist_criteria_page.${toSnakeCase(titleKey)}.description`)}
        </Typography>
      </>
    );
  },
);

CriteriaTitle.displayName = 'CriteriaTitle';
