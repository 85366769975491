import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

import { Question, Step, SubStep } from 'models/project-factsheet-form.model';

import { toSnakeCase } from 'utils';

import { TRANSLATION_KEY } from '../constants';
import { QuestionField } from '../question-field/question-field';
import { StyledStepTitle } from '../styles';

const styles = {
  label: { fontSize: 14, fontWeight: 700, mb: 0.5 },
  description: { fontSize: 14, mb: 0.5 },
  container: { mb: 3, display: 'flex', flexDirection: 'column', gap: 3 },
};

export const useProjectFactsheetForm = (
  onUpdateForm: (fieldName: string) => Promise<void>,
) => {
  const { t } = useTranslation();

  const renderQuestions = useCallback(
    (
      questions: Record<string, Question>,
      sectionKey: string,
      translationKey: string,
    ) => (
      <Box sx={styles.container}>
        {Object.entries(questions).map(([id, question]) => (
          <QuestionField
            key={id}
            id={id}
            question={question}
            sectionKey={sectionKey}
            translationKey={translationKey}
            onUpdateForm={onUpdateForm}
          />
        ))}
      </Box>
    ),
    [onUpdateForm],
  );

  const renderQuestionsFromSubStep = useCallback(
    (
      questions: Record<string, Question>,
      sectionKey: string,
      stepKey: string,
      subStepKey: string,
    ) => (
      <Box sx={styles.container}>
        {Object.entries(questions).map(([id, question]) => (
          <QuestionField
            key={id}
            id={id}
            question={question}
            sectionKey={`${sectionKey}.steps.${stepKey}.subSteps.${subStepKey}`}
            translationKey={`${sectionKey}`}
            onUpdateForm={onUpdateForm}
          />
        ))}
      </Box>
    ),
    [onUpdateForm],
  );

  const renderSubSteps = useCallback(
    (
      subSteps: Record<string, SubStep>,
      sectionKey: string,
      stepKey: string,
    ) => (
      <Box>
        {Object.entries(subSteps).map(([subStepKey, subStep], index) => (
          <Box key={subStepKey}>
            <Typography sx={{ fontSize: 14, color: 'grey.600', mb: 3 }}>
              {index + 1}.{' '}
              {t(
                `${TRANSLATION_KEY}.${toSnakeCase(sectionKey)}.steps.${toSnakeCase(stepKey)}.sub_steps.${toSnakeCase(subStepKey)}.title`,
              )}
            </Typography>

            {renderQuestionsFromSubStep(
              subStep.questions,
              sectionKey,
              stepKey,
              subStepKey,
            )}

            {index < Object.keys(subSteps).length - 1 && (
              <Divider sx={{ my: 3 }} />
            )}
          </Box>
        ))}
      </Box>
    ),
    [renderQuestionsFromSubStep, t],
  );

  const renderSteps = useCallback(
    (steps: Record<string, Step>, sectionKey: string) => (
      <Box>
        {Object.entries(steps).map(([stepKey, step], index) => (
          <Box key={stepKey}>
            <StyledStepTitle>
              {t(`${TRANSLATION_KEY}.step`, { stepCount: index + 1 })}{' '}
              {t(
                `${TRANSLATION_KEY}.${toSnakeCase(sectionKey)}.steps.${toSnakeCase(step.title)}.title`,
              )}
            </StyledStepTitle>

            {step.subSteps &&
              renderSubSteps(step.subSteps, sectionKey, stepKey)}

            {step.questions &&
              renderQuestions(
                step.questions,
                `${sectionKey}.steps.${stepKey}`,
                sectionKey,
              )}
          </Box>
        ))}
      </Box>
    ),
    [renderQuestions, renderSubSteps, t],
  );

  return { renderQuestions, renderSteps };
};
