import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from 'routes/constants';

import { Box, Grid } from '@mui/material';

import {
  StyledClickableBlock,
  StyledProcessStepWrapper,
  StyledTitle,
} from 'components/processes/process-step/styles';

import { ReactComponent as ArrowCircleRightIcon } from 'assets/arrow-circle-right-icon.svg';

import { InvestmentPlanSectionItemProps } from './types';

export const InvestmentPlanSectionItem = ({
  item,
}: InvestmentPlanSectionItemProps) => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const navigate = useNavigate();

  const { path, title, isLastBlock } = item;

  return (
    <Grid
      item
      xs={3}
      sx={{ cursor: 'pointer' }}
      onClick={() =>
        navigate(
          `/${Routes.ORGANIZATIONS}/${organizationId}/${Routes.INVESTMENT_PLAN}/${path}`,
        )
      }
    >
      <StyledProcessStepWrapper isLastStep={isLastBlock}>
        <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: 145 }}>
          <StyledTitle>{t(`home_screen.investment_plan.${title}`)}</StyledTitle>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <StyledClickableBlock>
            <ArrowCircleRightIcon />
          </StyledClickableBlock>
        </Box>
      </StyledProcessStepWrapper>
    </Grid>
  );
};
