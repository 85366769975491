import { useGetTechnicalAndEfficiencyAssessmentFormByOrganizationId } from 'hooks/queries/utility-assessment/utility-assessment';
import { createContext } from 'react';
import { useParams } from 'react-router-dom';

import {
  UtilityAssessmentContextProviderProps,
  UtilityAssessmentContextType,
} from './types';

const initialUtilityAssessmentTechnicalAndEfficiencyAssessmentDataContext = {
  isFetchingTechnicalAndEfficiencyAssessmentData: false,
  preliminaryQuestions: {},
  generalData: {},
  artesianWells: [],
  workOfWaterSupplyService: [],
  operationOfBoostingPumpingStations: [],
  operationOfWastewaterPumpingStations: [],
  comments: [],
};

const initialUtilityAssessmentContext: UtilityAssessmentContextType = {
  technicalAndEfficiencyAssessmentTab: {
    isFetchingTechnicalAndEfficiencyAssessmentData: false,
    preliminaryQuestions: {},
    generalData: {},
    artesianWells: [],
    workOfWaterSupplyService: [],
    operationOfBoostingPumpingStations: [],
    operationOfWastewaterPumpingStations: [],
    comments: [],
  },
};

export const UtilityAssessmentContext = createContext(
  initialUtilityAssessmentContext,
);

export const UtilityAssessmentContextProvider = ({
  children,
}: UtilityAssessmentContextProviderProps) => {
  const { organizationId } = useParams();
  const {
    data: technicalAndEfficiencyAssessmentData,
    isFetching: isFetchingTechnicalAndEfficiencyAssessmentData,
  } =
    useGetTechnicalAndEfficiencyAssessmentFormByOrganizationId(organizationId);

  return (
    <UtilityAssessmentContext.Provider
      value={{
        technicalAndEfficiencyAssessmentTab: {
          isFetchingTechnicalAndEfficiencyAssessmentData,
          ...(technicalAndEfficiencyAssessmentData ??
            initialUtilityAssessmentTechnicalAndEfficiencyAssessmentDataContext),
        },
      }}
    >
      {children}
    </UtilityAssessmentContext.Provider>
  );
};
