import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  name: yup.object().shape({
    en: yup
      .string()
      .transform((value) => (typeof value === 'string' ? value.trim() : value))
      .required('English name is required')
      .min(3, 'English name must be at least 3 characters')
      .max(200, 'English name must not exceed 200 characters'),
    ua: yup
      .string()
      .transform((value) => (typeof value === 'string' ? value.trim() : value))
      .required('Ukrainian name is required')
      .min(3, 'Ukrainian name must be at least 3 characters')
      .max(200, 'Ukrainian name must not exceed 200 characters'),
  }),
  address: yup
    .string()
    .required('Address is required')
    .max(200, 'Address must not exceed 200 characters'),
  edrpouCode: yup
    .string()
    .required('EDRPOU code is required')
    .matches(/^\d{8}$/, 'EDRPOU code must be exactly 8 digits'),
  storageUrl: yup.string(),
  phones: yup
    .array()
    .required()
    .of(
      yup.object().shape({
        value: yup.string().required('Phone is required'),
      }),
    )
    .min(1, 'At least one phone is required'),
});
