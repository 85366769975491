import { Auth0ContextInterface } from '@auth0/auth0-react';

import { Organization } from 'models/organization.model';
import { UserModel } from 'models/user.model';

import {
  axiosDelete,
  axiosGet,
  axiosPost,
  axiosUpdate,
} from './external-api.service';

export const organizationService = {
  // TODO: refactor attaching of the Access token
  getOrganizationById: (auth: Auth0ContextInterface<UserModel>, id?: string) =>
    axiosGet<Organization>(
      auth,
      `/v1/organizations/${id ?? auth.user?.app_metadata?.orgId}`,
    ),

  getOrganizations(auth: Auth0ContextInterface<UserModel>) {
    return axiosGet<Organization[]>(auth, '/v1/organizations');
  },

  createOrganization(
    auth: Auth0ContextInterface<UserModel>,
    data: Partial<Organization>,
  ) {
    return axiosPost(auth, '/v1/organizations', data);
  },

  updateOrganization(
    auth: Auth0ContextInterface<UserModel>,
    id: string,
    data: Partial<Organization>,
  ) {
    return axiosUpdate(auth, `/v1/organizations/${id}`, data);
  },

  deleteOrganization(auth: Auth0ContextInterface<UserModel>, id: string) {
    return axiosDelete(auth, `/v1/organizations/${id}`);
  },
};
