import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import { SCORE_BACKGROUND_COLOR } from 'pages/shortlist-criteria-page/constants';

import { StyledScoreGridWrapper, StyledScoreWrapper } from '../styles';
import { CriteriaOptionsProps } from './types';

export const CriteriaOptions = memo(({ options }: CriteriaOptionsProps) => {
  const { t } = useTranslation();

  return (
    <Grid container columnGap={1} pt={2}>
      {options.map((option) => (
        <StyledScoreGridWrapper item xs key={option.key}>
          <StyledScoreWrapper bgcolor={SCORE_BACKGROUND_COLOR[option.score]}>
            {option.score}
          </StyledScoreWrapper>

          <Typography sx={{ fontSize: 12, mt: 1 }}>
            {t(`dropdown_options.${option.key}`)}
          </Typography>
        </StyledScoreGridWrapper>
      ))}
    </Grid>
  );
});

CriteriaOptions.displayName = 'CriteriaOptions';
