import { FC, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Menu as MenuComponent, MenuItem } from '@mui/material';

import { theme } from 'configs/theme';

import { MenuProps } from './types';

export const Menu: FC<MenuProps> = ({
  options,
  anchorEl,
  open,
  onClose,
  onOptionClick,
}) => {
  const { t } = useTranslation();

  return (
    <MenuComponent
      anchorEl={anchorEl}
      open={!!open}
      onClose={(event: SyntheticEvent) => {
        event.stopPropagation();
        onClose?.();
      }}
      slotProps={{ paper: { sx: { boxShadow: theme.shadow.medium } } }}
    >
      {options?.map((option) => (
        <MenuItem
          key={option}
          onClick={(event) => {
            event.stopPropagation();
            onOptionClick?.(option);
          }}
        >
          {t(option)}
        </MenuItem>
      ))}
    </MenuComponent>
  );
};
